// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { LineFilled as LineFilledSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const LineFilled = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={LineFilledSvg} />
);

LineFilled.displayName = 'LineFilled';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(LineFilled);
