// This icon file is generated automatically.

import { IconDefinition } from '../types';

const GoalOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 20 20',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M3.75 8.92.5 7.07V3.36L3.75 1.5 7 3.36v3.7L3.75 8.93m0-1.72 1.75-1V4.23l-1.75-1-1.75 1V6.2l1.75.99m10.63 2.15V7.61l3.12 1.82v6.71l-5.75 3.36L6 16.14v-6.7L9.12 7.6v1.74l-1.62.94v5l4.25 2.46L16 15.28V10.3l-1.62-.94M11 12V.5h8.5l-1.83 2.75L19.5 6h-7v6z',
        },
      },
    ],
  },
  name: 'goal',
  theme: 'outlined',
};

export default GoalOutlined;
