// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { PoolOutlined as PoolOutlinedSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const PoolOutlined = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={PoolOutlinedSvg} />
);

PoolOutlined.displayName = 'PoolOutlined';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(PoolOutlined);
