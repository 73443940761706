// This icon file is generated automatically.

import { IconDefinition } from '../types';

const ConfirmFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 28 32',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'm15.04.1 10.39 3.48a2.83 2.83 0 0 1 1.96 2.66l.06 10.4a12.9 12.9 0 0 1-3.23 8.6 13.23 13.23 0 0 1-3.8 2.88l-5.58 2.98a1.22 1.22 0 0 1-.56.14 1.2 1.2 0 0 1-.56-.13l-5.64-2.92a13.26 13.26 0 0 1-3.83-2.84 12.91 12.91 0 0 1-3.34-8.57L.84 6.39a2.84 2.84 0 0 1 1.92-2.68L13.11.11a2.96 2.96 0 0 1 1.93-.02m4.97 11.15a1.2 1.2 0 0 0-1.67 0l-5.28 5.2-2.16-2.07a1.2 1.2 0 0 0-1.67.01 1.13 1.13 0 0 0 .01 1.63l3 2.88a1.2 1.2 0 0 0 1.67-.01l6.1-6.02a1.13 1.13 0 0 0 0-1.62',
        },
      },
    ],
  },
  name: 'confirm',
  theme: 'filled',
};

export default ConfirmFilled;
