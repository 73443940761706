// This icon file is generated automatically.

import { IconDefinition } from '../types';

const EditorChoiceOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 12 14',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M2.46 14V8.94L.24 5.34 3.12.66h5.76l2.88 4.66-2.22 3.61V14L6 12.8 2.46 14m1-1.41L6 11.74l2.54.85V10H3.46v2.59m.21-10.92L1.41 5.33 3.67 9h4.66l2.26-3.67-2.26-3.66H3.67M5.3 7.8 3.17 5.7l.71-.71L5.3 6.4l2.82-2.83.71.7L5.3 7.8',
        },
      },
    ],
  },
  name: 'editor-choice',
  theme: 'outlined',
};

export default EditorChoiceOutlined;
