// This icon file is generated automatically.

import { IconDefinition } from '../types';

const HomeOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 22 18',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M3.5 17.5V8.24L1.63 9.67l-.9-1.2L11 .64l10.27 7.85-.9 1.18-1.87-1.42v9.26h-15ZM5 16h12V7.1l-6-4.57L5 7.1V16',
        },
      },
    ],
  },
  name: 'home',
  theme: 'outlined',
};

export default HomeOutlined;
