// This icon file is generated automatically.

import { IconDefinition } from '../types';

const AnnounceFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 28 28',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M19.6 0H2.8A2.8 2.8 0 0 0 0 2.8v21A4.2 4.2 0 0 0 4.2 28H21a1.4 1.4 0 0 0 1.4-1.4V2.8A2.8 2.8 0 0 0 19.6 0M6.3 5.6h4.2a1.4 1.4 0 0 1 0 2.8H6.3a1.4 1.4 0 1 1 0-2.8m9.8 17.5H6.3a1.4 1.4 0 1 1 0-2.8h9.8a1.4 1.4 0 1 1 0 2.8m0-4.9H6.3a1.4 1.4 0 1 1 0-2.8h9.8a1.4 1.4 0 1 1 0 2.8m0-4.9H6.3a1.4 1.4 0 0 1 0-2.8h9.8a1.4 1.4 0 1 1 0 2.8',
        },
      },
      {
        tag: 'path',
        attrs: {
          d: 'M23.8 28H21a1.4 1.4 0 0 1-1.4-1.4V9.8A1.4 1.4 0 0 1 21 8.4h5.6A1.4 1.4 0 0 1 28 9.8v14a4.2 4.2 0 0 1-4.2 4.2m-1.4-2.8h1.4a1.4 1.4 0 0 0 1.4-1.4V11.2h-2.8z',
        },
      },
    ],
  },
  name: 'announce',
  theme: 'filled',
};

export default AnnounceFilled;
