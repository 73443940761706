// This icon file is generated automatically.

import { IconDefinition } from '../types';

const ListMenuFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 24 24',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'rect',
        attrs: { height: '6', rx: '2', width: '8', x: '1', y: '2' },
      },
      {
        tag: 'rect',
        attrs: { height: '6', rx: '2', width: '13', x: '10', y: '2' },
      },
      {
        tag: 'rect',
        attrs: { height: '6', rx: '2', width: '8', x: '1', y: '9' },
      },
      {
        tag: 'rect',
        attrs: { height: '6', rx: '2', width: '13', x: '10', y: '9' },
      },
      {
        tag: 'rect',
        attrs: { height: '6', rx: '2', width: '8', x: '1', y: '16' },
      },
      {
        tag: 'rect',
        attrs: { height: '6', rx: '2', width: '13', x: '10', y: '16' },
      },
    ],
  },
  name: 'list-menu',
  theme: 'filled',
};

export default ListMenuFilled;
