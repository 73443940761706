// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { HomeOutlined as HomeOutlinedSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const HomeOutlined = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={HomeOutlinedSvg} />
);

HomeOutlined.displayName = 'HomeOutlined';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(HomeOutlined);
