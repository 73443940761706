// This icon file is generated automatically.

import { IconDefinition } from '../types';

const BankFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 40 31.79',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M11.67 3.18 3.35 6.4l-.02 2.4v2.42H1.48l-.1.13a.34.34 0 0 0 .02.5l.12.13h2.6l.02.68.02.68.57.01.57.02v13.12H4.2v1.74H1.53v1.16l-.64.02c-.63.02-.64.02-.77.16S0 29.73 0 30.58a3.65 3.65 0 0 0 .07 1.01.4.4 0 0 0 .46.18c.23-.05.29-.26.29-.95v-.6h38.36v.57c0 .62.06.87.2.95a.4.4 0 0 0 .49-.07c.13-.13.13-.14.13-1.05s0-.92-.13-1.06-.13-.14-.74-.16l-.62-.02v-1.16h-2.65v-1.74h-1.13V13.35h1.13v-1.38h2.6l.13-.12a.34.34 0 0 0 .02-.5l-.1-.14h-1.78l-.01-2.4-.02-2.41-8.26-3.2L20.09 0c-.05-.01-3.84 1.41-8.42 3.18m8.8-.08a1.5 1.5 0 0 1 .76.65 1.43 1.43 0 0 1 0 1.27 1.4 1.4 0 0 1-2.26.25 1.3 1.3 0 0 1 .64-2.19 1.97 1.97 0 0 1 .86.02Zm-7.89 9.55.02.68.57.01.57.02v13.12l-.57.01-.57.02-.02.85-.01.86h-1.94l-.01-.86-.02-.85-.57-.02-.57-.01V13.36l.57-.02.57-.01.02-.68.01-.67h1.94Zm8.42.01v.7h1.17v13.12H21v1.74h-1.94l-.01-.86-.02-.85-.57-.02-.58-.01V13.36l.58-.02.57-.01.02-.68.01-.67H21Zm8.43 0v.7h1.17v13.12h-1.18v1.74h-1.99v-1.74h-1.12V13.35h1.12v-1.38h2Z',
        },
      },
    ],
  },
  name: 'bank',
  theme: 'filled',
};

export default BankFilled;
