// This icon file is generated automatically.

import { IconDefinition } from '../types';

const PersonalCardOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 14 14',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M1.87 13.33c-.34 0-.62-.11-.85-.35a1.16 1.16 0 0 1-.35-.85V5.2c0-.33.11-.62.35-.85.23-.23.51-.35.85-.35h3.46V1.67c0-.28.1-.52.3-.71.19-.2.43-.3.7-.3h1.34c.27 0 .5.1.7.3.2.2.3.43.3.7V4h3.46c.33 0 .62.12.85.35.24.23.35.52.35.85v6.93c0 .33-.11.62-.35.85-.23.24-.52.35-.85.35H1.87m0-1h10.26a.2.2 0 0 0 .15-.05.2.2 0 0 0 .05-.15V5.2a.2.2 0 0 0-.05-.14.2.2 0 0 0-.15-.06H8.67v.26c0 .27-.1.5-.3.7-.2.2-.43.3-.7.3H6.33c-.27 0-.5-.1-.7-.3a.96.96 0 0 1-.3-.7V5H1.87a.2.2 0 0 0-.15.06.2.2 0 0 0-.05.14v6.93c0 .06.02.1.05.15a.2.2 0 0 0 .15.05m1.23-1.52h3.8v-.2a.84.84 0 0 0-.15-.47.95.95 0 0 0-.39-.34 3.43 3.43 0 0 0-2.06-.22c-.22.05-.44.12-.66.22-.17.08-.3.2-.4.34-.09.14-.14.3-.14.47v.2m5.23-1.02H11V9H8.33v.8M5 9c.25 0 .46-.09.63-.26a.86.86 0 0 0 .27-.64.86.86 0 0 0-.27-.63A.86.86 0 0 0 5 7.2a.86.86 0 0 0-.63.27.86.86 0 0 0-.27.63c0 .25.09.46.27.64.17.17.38.26.63.26m3.33-1H11v-.8H8.33V8m-2-2.74h1.34v-3.6H6.33v3.6',
        },
      },
    ],
  },
  name: 'personal-card',
  theme: 'outlined',
};

export default PersonalCardOutlined;
