// This icon file is generated automatically.

import { IconDefinition } from '../types';

const CloseEyeFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 34 27',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M10.6 15.33a6.64 6.64 0 0 1 8.13-8.14l4.2-4.2a16.87 16.87 0 0 0-5.92-1.01c-9.44 0-13.75 6.72-15.82 9.94l-.48.76a1.61 1.61 0 0 0 0 1.83l.48.75a26.81 26.81 0 0 0 4.18 5.31l5.24-5.24zM17 20.25a6.52 6.52 0 0 1-3.36-.93l2.53-2.52a3.23 3.23 0 0 0 4.01-4.02l2.52-2.53a6.62 6.62 0 0 1-5.71 9.99zm16.31-7.55-.48-.75a25.18 25.18 0 0 0-5.42-6.39l2.39-2.39A1.67 1.67 0 0 0 27.44.81L17.15 11.1 4.22 24.03c-.67.63-.7 1.68-.07 2.35.63.67 1.68.7 2.36.07l.07-.07 2.9-2.92a16.26 16.26 0 0 0 7.53 1.74c9.44 0 13.75-6.72 15.82-9.94l.48-.76a1.6 1.6 0 0 0 0-1.82z',
        },
      },
    ],
  },
  name: 'close-eye',
  theme: 'filled',
};

export default CloseEyeFilled;
