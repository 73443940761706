// This icon file is generated automatically.

import { IconDefinition } from '../types';

const DiscordFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 100 100',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M84.65 18.2a82.47 82.47 0 0 0-20.35-6.3.3.3 0 0 0-.33.15 57.45 57.45 0 0 0-2.53 5.2 76.12 76.12 0 0 0-22.86 0 52.7 52.7 0 0 0-2.58-5.2.32.32 0 0 0-.32-.16 82.24 82.24 0 0 0-20.36 6.32.3.3 0 0 0-.13.11C2.22 37.7-1.33 56.58.4 75.24c.01.1.06.18.13.23A82.93 82.93 0 0 0 25.51 88.1c.13.04.27-.01.35-.12a59.27 59.27 0 0 0 5.11-8.3.32.32 0 0 0-.17-.45 54.6 54.6 0 0 1-7.8-3.72.32.32 0 0 1-.03-.53c.52-.39 1.05-.8 1.55-1.21.09-.08.21-.1.32-.05 16.37 7.48 34.08 7.48 50.26 0a.3.3 0 0 1 .32.04c.5.42 1.03.83 1.56 1.22.18.14.16.42-.03.53a51.25 51.25 0 0 1-7.8 3.72.32.32 0 0 0-.18.44c1.5 2.91 3.22 5.68 5.1 8.3.09.12.23.17.36.13a82.65 82.65 0 0 0 25-12.63.32.32 0 0 0 .14-.23c2.08-21.57-3.5-40.3-14.79-56.91a.25.25 0 0 0-.13-.12M33.42 63.89c-4.93 0-8.99-4.52-8.99-10.08 0-5.55 3.98-10.08 8.99-10.08 5.04 0 9.06 4.57 8.98 10.08 0 5.56-3.98 10.08-8.98 10.08m33.22 0c-4.92 0-8.98-4.52-8.98-10.08 0-5.55 3.98-10.08 8.98-10.08 5.05 0 9.07 4.57 9 10.08 0 5.56-3.95 10.08-9 10.08',
        },
      },
    ],
  },
  name: 'discord',
  theme: 'filled',
};

export default DiscordFilled;
