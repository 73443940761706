// This icon file is generated automatically.

import { IconDefinition } from '../types';

const FooterOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 20 20',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M10 19.5a9.6 9.6 0 0 1-8.75-5.8A9.24 9.24 0 0 1 .5 10a9.6 9.6 0 0 1 5.8-8.75A9.24 9.24 0 0 1 10 .5a9.6 9.6 0 0 1 8.75 5.8c.5 1.15.75 2.39.75 3.7s-.25 2.55-.75 3.7a9.6 9.6 0 0 1-5.05 5.05c-1.15.5-2.39.75-3.7.75m4.83-11.71 1.54-.5.44-1.53a8.2 8.2 0 0 0-4.58-3.45l-1.48 1.05v1.57l4.08 2.86Zm-9.66 0 4.08-2.86V3.36L7.77 2.3A8 8 0 0 0 3.2 5.76l.44 1.52 1.52.5ZM4 15.28l1.36-.12.87-1.49-1.47-4.42-1.6-.56L2 9.53c0 1.13.15 2.15.44 3.07.3.91.81 1.8 1.55 2.68M10 18a8.06 8.06 0 0 0 2.6-.42l.77-1.67-.76-1.28H7.39l-.74 1.28.77 1.67A8.15 8.15 0 0 0 10 18m-2.35-4.87h4.72L13.8 8.9 10 6.24 6.23 8.9l1.42 4.23m8.36 2.15a7.91 7.91 0 0 0 1.55-2.68c.3-.92.44-1.94.44-3.07l-1.13-.8-1.62.5-1.47 4.44.87 1.49 1.36.12',
        },
      },
    ],
  },
  name: 'footer',
  theme: 'outlined',
};

export default FooterOutlined;
