// This icon file is generated automatically.

import { IconDefinition } from '../types';

const InfinityOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 34 16',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M26.03.03c-2.24 0-4.26.93-5.71 2.41l-1.93 1.99 2.09 2.14 1.98-2.04A4.97 4.97 0 0 1 31.01 8a4.99 4.99 0 0 1-8.55 3.47l-8.78-9.03a7.97 7.97 0 1 0 0 11.11l1.93-1.98-2.09-2.14-1.98 2.04A4.96 4.96 0 0 1 2.99 8a4.99 4.99 0 0 1 8.55-3.47l8.78 9.03A7.97 7.97 0 1 0 26.03.03Z',
        },
      },
    ],
  },
  name: 'infinity',
  theme: 'outlined',
};

export default InfinityOutlined;
