// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { OpenEyeFilled as OpenEyeFilledSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const OpenEyeFilled = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={OpenEyeFilledSvg} />
);

OpenEyeFilled.displayName = 'OpenEyeFilled';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(OpenEyeFilled);
