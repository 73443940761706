// This icon file is generated automatically.

import { IconDefinition } from '../types';

const LockOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 10 14',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M1.2 13.83c-.33 0-.61-.11-.85-.35a1.16 1.16 0 0 1-.35-.85V6.37c0-.33.12-.61.35-.85.24-.24.52-.35.86-.35H2V3.83c0-.83.3-1.54.88-2.12A2.9 2.9 0 0 1 5 .83c.83 0 1.54.3 2.12.88.59.58.88 1.3.88 2.12v1.34h.8c.33 0 .61.11.85.35.23.24.35.52.35.85v6.26c0 .33-.12.61-.35.85-.24.24-.52.35-.86.35H1.21Zm0-1h7.6a.2.2 0 0 0 .14-.05.2.2 0 0 0 .06-.15V6.37a.2.2 0 0 0-.06-.15.2.2 0 0 0-.15-.05H1.21a.2.2 0 0 0-.15.05.2.2 0 0 0-.06.15v6.26c0 .06.02.1.06.15a.2.2 0 0 0 .15.05M5 10.67c.32 0 .6-.12.83-.34.22-.23.34-.5.34-.83 0-.32-.12-.6-.34-.83-.23-.22-.5-.34-.83-.34-.32 0-.6.12-.83.34-.22.23-.34.5-.34.83 0 .32.12.6.34.83.23.22.5.34.83.34m-2-5.5h4V3.83c0-.55-.2-1.02-.58-1.41A1.93 1.93 0 0 0 5 1.83c-.56 0-1.03.2-1.42.59C3.2 2.8 3 3.28 3 3.83v1.34',
        },
      },
    ],
  },
  name: 'lock',
  theme: 'outlined',
};

export default LockOutlined;
