// This icon file is generated automatically.

import { IconDefinition } from '../types';

const PoolOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 16 16',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M6.13 14.2c-1.2 0-2.22-.42-3.07-1.26A4.18 4.18 0 0 1 1.8 9.87c0-1.2.43-2.22 1.27-3.06a4.18 4.18 0 0 1 3.07-1.27c1.2 0 2.22.42 3.06 1.27a4.18 4.18 0 0 1 1.27 3.06c0 1.2-.42 2.22-1.27 3.07a4.18 4.18 0 0 1-3.06 1.27m0-1c.92 0 1.7-.32 2.36-.97.65-.65.97-1.44.97-2.36 0-.92-.32-1.7-.97-2.36a3.21 3.21 0 0 0-2.36-.97c-.92 0-1.71.32-2.36.97a3.21 3.21 0 0 0-.98 2.36c0 .92.33 1.71.98 2.36.65.65 1.44.98 2.36.98m5.1-2.98a.67.67 0 0 0 .03-.16v-.19c0-1.42-.5-2.63-1.5-3.63s-2.21-1.5-3.63-1.5h-.19l-.16.02a4.28 4.28 0 0 1 4.1-2.97c1.2 0 2.21.43 3.06 1.27a4.18 4.18 0 0 1 1.27 3.07 4.28 4.28 0 0 1-2.97 4.1',
        },
      },
      {
        tag: 'path',
        attrs: { d: 'M5.2 8.8v.45h1.29l-1.01 2.28h.53l1-2.34V8.8H5.2' },
      },
    ],
  },
  name: 'pool',
  theme: 'outlined',
};

export default PoolOutlined;
