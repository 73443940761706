// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { AlarmOffOutlined as AlarmOffOutlinedSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const AlarmOffOutlined = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={AlarmOffOutlinedSvg} />
);

AlarmOffOutlined.displayName = 'AlarmOffOutlined';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(
  AlarmOffOutlined,
);
