// This icon file is generated automatically.

import { IconDefinition } from '../types';

const CallCenterOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 26 24',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M12.18 23.33v-2h9.74c.11 0 .21-.03.3-.1a.33.33 0 0 0 .11-.27v-9.32c0-2.55-.9-4.7-2.73-6.44A9.2 9.2 0 0 0 13 2.58 9.2 9.2 0 0 0 6.4 5.2a8.55 8.55 0 0 0-2.73 6.44v7.85h-1c-.64 0-1.2-.23-1.65-.67a2.18 2.18 0 0 1-.69-1.63V14.6c0-.43.13-.83.37-1.19.25-.36.57-.64.97-.86l.06-1.7c.1-1.46.48-2.8 1.13-4.04a11.11 11.11 0 0 1 5.97-5.38C10.15.93 11.54.67 13 .67a11.34 11.34 0 0 1 10.13 6.12 10.69 10.69 0 0 1 1.14 4.03l.06 1.67c.4.19.71.45.96.79s.38.72.38 1.14v2.97c0 .42-.13.8-.38 1.14-.25.34-.57.6-.96.79v1.64c0 .66-.23 1.22-.7 1.68-.47.46-1.04.7-1.7.7h-9.75m-2.92-8.97a1.16 1.16 0 1 1 0-2.32c.32 0 .6.11.83.34.23.22.35.5.35.83 0 .32-.12.6-.35.82-.23.22-.5.33-.83.33m7.48 0a1.16 1.16 0 1 1 0-2.32c.33 0 .6.11.84.34.23.22.34.5.34.83 0 .32-.11.6-.34.82-.23.22-.51.33-.84.33M5.5 12.26a7.07 7.07 0 0 1 2.04-5.58 7.39 7.39 0 0 1 5.54-2.32c1.85 0 3.48.57 4.9 1.72a7.01 7.01 0 0 1 2.57 4.44c-1.9-.02-3.65-.52-5.25-1.5a9.73 9.73 0 0 1-3.7-4 9.92 9.92 0 0 1-6.1 7.24',
        },
      },
    ],
  },
  name: 'call-center',
  theme: 'outlined',
};

export default CallCenterOutlined;
