// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { CloseCircleOutlined as CloseCircleOutlinedSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const CloseCircleOutlined = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={CloseCircleOutlinedSvg} />
);

CloseCircleOutlined.displayName = 'CloseCircleOutlined';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(
  CloseCircleOutlined,
);
