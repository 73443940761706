// This icon file is generated automatically.

import { IconDefinition } from '../types';

const SlotOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 22 17',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M20.64.52A1.74 1.74 0 0 0 19.36 0H2.21C1.7 0 1.28.17.93.52S.41 1.3.41 1.8v13.39c0 .51.17.93.52 1.28s.78.52 1.28.52h17.15c.51 0 .93-.17 1.28-.52s.53-.78.53-1.28V1.81c0-.51-.17-.93-.53-1.28zM2 1.59a.3.3 0 0 1 .21-.1h17.15c.08 0 .15.03.21.1s.1.14.1.21v5.78H1.9V1.81c0-.08.03-.15.1-.21v-.01ZM13.35 15.5H8.4V8.99h4.95zM2 15.4a.3.3 0 0 1-.1-.21v-6.2h5.26v6.51H2.21a.26.26 0 0 1-.21-.1Zm17.58 0a.26.26 0 0 1-.21.1h-4.78V8.99h5.09v6.2c0 .08-.03.15-.1.21',
        },
      },
      {
        tag: 'path',
        attrs: {
          d: 'M5.1 6.17c-.4 0-.63-.23-.66-.58h-.93c.01.82.71 1.29 1.6 1.29.98 0 1.53-.61 1.53-1.29 0-1.52-2.22-1.04-2.22-1.91 0-.35.25-.52.59-.51.37 0 .6.23.62.51h.95c-.06-.78-.66-1.23-1.55-1.23s-1.52.47-1.52 1.25c0 1.57 2.22 1 2.22 1.94 0 .31-.24.53-.65.53h.02M9.69 6.15H8.26V2.51H7.4v4.32h2.29v-.68M12.24 6.88c1.23 0 2.21-.92 2.21-2.22 0-1.3-.98-2.21-2.21-2.21s-2.22.92-2.22 2.21 1 2.22 2.22 2.22m0-3.65c.78 0 1.32.55 1.32 1.43 0 .88-.54 1.44-1.32 1.44s-1.32-.56-1.32-1.44c0-.88.54-1.43 1.32-1.43M16.02 6.83h.87V3.21h1.15v-.7h-3.17v.7h1.15v3.62M3.36 10.41v.67h1.93L3.78 14.5h.8l1.49-3.51v-.58H3.36M9.96 14.5h.8l1.49-3.51v-.58H9.54v.67h1.93L9.96 14.5M15.72 11.08h1.93l-1.51 3.42h.8l1.5-3.51v-.58h-2.72z',
        },
      },
    ],
  },
  name: 'slot',
  theme: 'outlined',
};

export default SlotOutlined;
