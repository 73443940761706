// This icon file is generated automatically.

import { IconDefinition } from '../types';

const BanksOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 22 16',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M12.75 8.71c-.7 0-1.29-.24-1.77-.72a2.41 2.41 0 0 1-.73-1.78c0-.7.24-1.29.73-1.77a2.41 2.41 0 0 1 1.77-.73c.7 0 1.29.24 1.77.73.49.48.73 1.07.73 1.77s-.24 1.3-.73 1.78c-.48.48-1.07.72-1.77.72m-6.6 3.1c-.5 0-.92-.18-1.27-.53A1.74 1.74 0 0 1 4.35 10V2.42c0-.5.17-.92.53-1.27.35-.36.78-.53 1.27-.53h13.2c.5 0 .92.17 1.27.53.36.35.53.78.53 1.27V10c0 .5-.17.92-.53 1.28-.35.35-.78.53-1.27.53H6.15m1.5-1.5h10.2c0-.5.17-.93.53-1.28.35-.35.78-.53 1.27-.53V3.92c-.5 0-.92-.17-1.27-.53a1.74 1.74 0 0 1-.53-1.27H7.65c0 .5-.17.92-.53 1.27-.35.36-.78.53-1.27.53V8.5c.5 0 .92.18 1.27.53.36.36.53.78.53 1.28m10.52 5H2.65c-.5 0-.92-.18-1.27-.53a1.74 1.74 0 0 1-.53-1.28V3.6h1.5v9.9c0 .08.03.15.1.21.06.07.13.1.2.1h15.52v1.5m-12.02-5h-.3V2.1h.3a.3.3 0 0 0-.21.1.3.3 0 0 0-.1.21V10c0 .08.04.16.1.22.06.06.13.09.21.09',
        },
      },
    ],
  },
  name: 'banks',
  theme: 'outlined',
};

export default BanksOutlined;
