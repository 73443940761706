// This icon file is generated automatically.

import { IconDefinition } from '../types';

const TwitterFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 22 18',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M10.85 5.54C9.98.89 15.52-1.87 18.8 1.48c0 0 1.46-.41 2.74-1.24 0 0-.46 1.6-2.04 2.59 0 0 1.75-.24 2.51-.7 0 0-.76 1.58-2.16 2.17C20.6 14.94 9.22 21.4 0 16.12c0 0 4.55.23 6.48-2 0 0-2.8.24-4.2-3.17 0 0 1.05.4 2.1-.12 0 0-3.27-.53-3.57-4.47 0 0 1.06.82 2.22.53 0 0-3.62-2.18-1.58-6.12 0 0 3.86 4.94 9.4 4.77',
          fill: 'currentColor',
        },
      },
    ],
  },
  name: 'twitter',
  theme: 'filled',
};

export default TwitterFilled;
