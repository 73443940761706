// This icon file is generated automatically.

import { IconDefinition } from '../types';

const ExitDoorOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 21 18',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M17.37 9.75H5.57v-1.5h11.8l-1.82-1.82 1.06-1.08L20.26 9l-3.65 3.65-1.06-1.08 1.82-1.82M12.2 5.87V2.3a.3.3 0 0 0-.08-.22.3.3 0 0 0-.23-.09H2.31a.3.3 0 0 0-.22.09.3.3 0 0 0-.09.22v13.38c0 .1.03.17.09.22a.3.3 0 0 0 .22.09h9.58a.3.3 0 0 0 .23-.09.3.3 0 0 0 .08-.22v-3.56h1.5v3.56c0 .5-.17.93-.53 1.28-.35.35-.78.53-1.28.53H2.31c-.5 0-.93-.18-1.28-.53a1.74 1.74 0 0 1-.53-1.28V2.31c0-.5.18-.93.53-1.28C1.38.68 1.81.5 2.31.5h9.58c.5 0 .93.18 1.28.53.36.35.53.78.53 1.28v3.56h-1.5',
        },
      },
    ],
  },
  name: 'exit-door',
  theme: 'outlined',
};

export default ExitDoorOutlined;
