// This icon file is generated automatically.

import { IconDefinition } from '../types';

const DownCircleOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 17 16',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'g',
        attrs: { 'clip-rule': 'evenodd', 'fill-rule': 'evenodd' },
        children: [
          {
            tag: 'path',
            attrs: {
              d: 'M11.76 6.17a.46.46 0 0 0-.7 0L8.9 8.62 6.76 6.17a.46.46 0 0 0-.7 0c-.2.22-.2.58 0 .8l2.5 2.86c.19.23.5.23.7 0l2.5-2.85c.2-.23.2-.59 0-.81',
            },
          },
          {
            tag: 'path',
            attrs: {
              d: 'M16.9 8a8 8 0 1 0-16 0 8 8 0 0 0 16 0m-8-6.86a6.86 6.86 0 1 1 0 13.72 6.86 6.86 0 0 1 0-13.72',
            },
          },
        ],
      },
    ],
  },
  name: 'down-circle',
  theme: 'outlined',
};

export default DownCircleOutlined;
