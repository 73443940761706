// This icon file is generated automatically.

import { IconDefinition } from '../types';

const RerollBoxOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 16 16',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M5.54 11c-.33 0-.62-.12-.85-.35a1.16 1.16 0 0 1-.36-.86V6.21c0-.34.12-.62.36-.86.23-.23.52-.35.85-.35h.59l.66-.67h2.42l.66.67h.6c.32 0 .6.12.84.35.24.24.36.52.36.86v3.58c0 .34-.12.62-.36.86-.23.23-.52.35-.85.35H5.54m0-1h4.92a.2.2 0 0 0 .15-.06.2.2 0 0 0 .06-.15V6.21a.2.2 0 0 0-.06-.15.2.2 0 0 0-.15-.06H5.54a.2.2 0 0 0-.15.06.2.2 0 0 0-.06.15v3.58c0 .06.02.11.06.15a.2.2 0 0 0 .15.06M6.16.56c.3-.08.6-.14.9-.18a7.56 7.56 0 0 1 6.12 1.96 7.82 7.82 0 0 1 2.49 5h-1a6.45 6.45 0 0 0-2.05-4.16 6.84 6.84 0 0 0-4.26-1.83l1.16 1.17-.7.7L6.16.56Zm3.68 14.88c-.3.08-.6.14-.9.18a7.56 7.56 0 0 1-6.12-1.96 7.82 7.82 0 0 1-2.49-5h1a6.68 6.68 0 0 0 6.31 5.99l-1.16-1.17.7-.7 2.66 2.66',
        },
      },
    ],
  },
  name: 'reroll-box',
  theme: 'outlined',
};

export default RerollBoxOutlined;
