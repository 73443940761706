// This icon file is generated automatically.

import { IconDefinition } from '../types';

const HeartTagOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 26 26',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'm14.33 19.95 4.36-4.44a2.37 2.37 0 0 0 .7-1.73c0-.66-.23-1.23-.7-1.7a2.3 2.3 0 0 0-1.7-.72c-.43 0-.85.12-1.27.35a5.8 5.8 0 0 0-1.39 1.22 6.6 6.6 0 0 0-1.44-1.24c-.39-.22-.8-.33-1.21-.33a2.3 2.3 0 0 0-1.7.72c-.47.47-.7 1.04-.7 1.7a2.46 2.46 0 0 0 .7 1.73l4.35 4.44m1.2 5.12c-.37.38-.85.58-1.42.58-.56 0-1.04-.2-1.42-.58L.95 13.34c-.19-.2-.34-.42-.45-.67a1.98 1.98 0 0 1-.17-.8V2.33c0-.54.2-1.01.6-1.4.39-.4.86-.6 1.4-.6h9.54a2.02 2.02 0 0 1 1.44.6l11.74 11.75c.39.4.59.87.59 1.44 0 .56-.19 1.04-.57 1.42l-9.53 9.53m-1.42-1.4 9.54-9.54-11.78-11.8H2.33v9.54l11.78 11.8M5.67 7.33c.46 0 .85-.16 1.18-.48.32-.33.48-.72.48-1.18 0-.46-.16-.86-.48-1.18A1.6 1.6 0 0 0 5.67 4c-.46 0-.86.16-1.18.49A1.6 1.6 0 0 0 4 5.67c0 .46.16.85.49 1.18.32.32.72.48 1.18.48Z',
        },
      },
    ],
  },
  name: 'heart-tag',
  theme: 'outlined',
};

export default HeartTagOutlined;
