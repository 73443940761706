// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { ConfirmFilled as ConfirmFilledSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const ConfirmFilled = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={ConfirmFilledSvg} />
);

ConfirmFilled.displayName = 'ConfirmFilled';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(ConfirmFilled);
