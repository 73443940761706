// This icon file is generated automatically.

import { IconDefinition } from '../types';

const LineFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 48 48',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          'clip-rule': 'evenodd',
          d: 'M7.1 47.47c.24-.02.45.06.64.2l.49.02c.34.05.68.13 1.01.2l.35.09c1.52.04 3.04.01 4.56-.02 1.26-.02 2.52-.04 3.78-.02h.48c.9.02 1.79.03 2.68-.03h.11c1.24.05 2.49.04 3.73.03h2.44c.67.01 1.34.02 2-.03h.12c.14.04.29.04.43.03h8.77l.08-.02c.33-.08.66-.16 1-.22l.38-.03c.21-.1.44-.1.66-.1.74-.15 1.4-.48 2.05-.83.46-.26.9-.53 1.3-.86.86-.63 1.5-1.47 2.12-2.32.22-.33.4-.69.59-1.04l.02-.05.16-.33c.13-.24.25-.48.31-.74.23-.5.33-1.04.43-1.58.05-.28.1-.56.18-.84.02-.24.01-.49.01-.73V12.78l.01-.9c.01-.81.02-1.62-.03-2.43l-.05-.26a3.6 3.6 0 0 1-.14-1.06 7.15 7.15 0 0 0-.66-2.3L47 5.6l-.12-.24c-.3-.6-.66-1.14-1.06-1.67-.16-.14-.3-.32-.43-.5-.18-.22-.35-.44-.58-.61a3.68 3.68 0 0 1-.36-.28c-.2-.18-.4-.36-.67-.45l-.57-.36-.57-.36c-.1-.16-.26-.25-.43-.33a5.29 5.29 0 0 0-2.07-.6l-.39-.04a2.03 2.03 0 0 0-.4-.08c-10.24-.1-20.48-.1-30.72 0-.14.01-.27.04-.4.08-.16 0-.31.06-.47.12-.18.06-.36.12-.56.1l-1.28.5-.36.14c-.45.1-.8.35-1.17.61l-.34.24a3 3 0 0 1-.35.3 3.57 3.57 0 0 0-.57.52 9.13 9.13 0 0 0-1.85 2.37c-.16.3-.3.61-.43.92a30.75 30.75 0 0 1-.2.5c-.16.5-.33 1-.43 1.52v.26c.01.4-.07.78-.14 1.16l-.06.26c-.02.25-.02.49-.02.73v.2a23044.67 23044.67 0 0 0 .02 27.57l.06.31c.07.33.14.65.13.99-.05.32.04.62.13.9a9.14 9.14 0 0 0 .6 1.78l.22.54c.04.14.13.25.23.36l.14.19a10.06 10.06 0 0 0 4 3.7l.37.18c.38.2.75.38 1.2.35m30.2-34.55a18.63 18.63 0 0 0-9.37-4.63c-.34-.07-.69-.13-1.04-.18h-.1l-.22-.02a21.2 21.2 0 0 0-10.69 1.45 15.96 15.96 0 0 0-7.47 6.06c-.8 1.24-1.4 2.57-1.64 4.04l-.06.29-.11.66c-.34 2.89.3 5.54 1.9 7.95 1.99 3 4.8 4.95 8.1 6.22.97.37 1.97.6 2.96.84l1.2.3h.17c.27 0 .54 0 .8.1.98.26 1.22 1.08 1.1 2.03-.03.28-.07.55-.12.82-.06.33-.12.65-.15.98-.08.92.27 1.17 1.13.86 2-.72 3.77-1.91 5.5-3.1a69.31 69.31 0 0 0 6.75-5.28A17.83 17.83 0 0 0 39.76 28a11.55 11.55 0 0 0 1.46-8.05l-.01-.15-.04-.31a13.25 13.25 0 0 0-3.88-6.57Z',
          'fill-rule': 'evenodd',
        },
      },
      {
        tag: 'path',
        attrs: {
          d: 'M27.17 22.63c.14-.22.1-.41.1-.6v-3.28c.01-.55.35-.87.87-.88.57-.02 1 .31 1 .87.02 2.29.02 4.58 0 6.87 0 .39-.2.72-.6.8-.42.1-.82.1-1.12-.31-.92-1.28-1.86-2.55-2.79-3.82-.15-.2-.27-.43-.55-.6v3.72c0 .7-.32 1.05-.93 1.06-.6.01-1-.33-1-.94V18.8a.9.9 0 0 1 .66-.9c.47-.15.87.02 1.17.43.82 1.13 1.65 2.24 2.48 3.36l.71.94M31.03 26.43c-.55-.01-.7-.43-.71-.84-.03-2.28-.02-4.55 0-6.82 0-.52.39-.89.88-.9 1.16 0 2.32-.01 3.47.01.43 0 .82.18.93.65.1.44.09.88-.42 1.1-.78.05-1.57 0-2.35.03-.18 0-.38-.06-.53.1-.13.41-.04.83-.05 1.24-.01.28.2.22.37.22h2.01c.32 0 .61.06.78.37.1.18.09.37.09.56 0 .64-.18.83-.81.84h-2.16c-.08.02-.2-.02-.22.12-.3 1.37-.23 1.46 1.12 1.46h1.19c.57.02.93.3 1 .77.1.61-.07.86-.7 1.1-1.3.06-2.6.07-3.9 0M13.22 18.12c.32-.34.72-.33 1.1-.16.4.18.52.56.52.99 0 1.7.01 3.4-.01 5.1 0 .4.1.54.5.52.64-.04 1.28-.02 1.92 0 .76 0 1.1.46.94 1.23-.26.43-.63.55-1.12.53-1.05-.03-2.1 0-3.14 0-.24 0-.49 0-.7-.12h-.01c-.12-.1-.13-.28-.22-.4-.08-.17-.04-.34-.04-.51V19c0-.17-.04-.34.04-.5.14-.1.12-.27.22-.4M19.21 18.26c.18-.46.6-.46.94-.37.4.11.75.35.75.86v6.65c0 .32-.03.62-.31.82-.3.16-.62.12-.92.09-.37-.05-.55-.3-.55-.7v-2.49c0-1.47.01-2.94-.01-4.41 0-.16.02-.3.1-.45',
        },
      },
    ],
  },
  name: 'line',
  theme: 'filled',
};

export default LineFilled;
