// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { ArrowLeftOutlined as ArrowLeftOutlinedSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const ArrowLeftOutlined = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={ArrowLeftOutlinedSvg} />
);

ArrowLeftOutlined.displayName = 'ArrowLeftOutlined';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(
  ArrowLeftOutlined,
);
