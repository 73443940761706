// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { PapersOutlined as PapersOutlinedSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const PapersOutlined = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={PapersOutlinedSvg} />
);

PapersOutlined.displayName = 'PapersOutlined';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(
  PapersOutlined,
);
