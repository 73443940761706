// This icon file is generated automatically.

import { IconDefinition } from '../types';

const PhoneOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 12 12',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M10.96 11.67c-1.26 0-2.52-.3-3.79-.88a12.05 12.05 0 0 1-3.5-2.47 12.14 12.14 0 0 1-2.46-3.5 8.97 8.97 0 0 1-.88-3.78.69.69 0 0 1 .7-.7h2.18c.17 0 .31.05.44.16.13.1.21.24.25.4l.38 1.97c.03.18.02.34-.02.47a.7.7 0 0 1-.2.33l-1.54 1.5c.25.45.53.88.85 1.28a12.7 12.7 0 0 0 2.2 2.18c.41.31.86.6 1.34.88L8.4 8c.1-.11.22-.19.37-.23.15-.04.3-.06.46-.03l1.86.37c.16.05.3.13.4.26.11.13.17.27.17.44v2.16a.69.69 0 0 1-.7.7M2.05 4.22l1.19-1.14A.12.12 0 0 0 3.28 3v-.08l-.3-1.5a.14.14 0 0 0-.04-.07.13.13 0 0 0-.08-.03H1.43a.09.09 0 0 0-.09.1 8.53 8.53 0 0 0 .7 2.79Zm5.8 5.76a7.84 7.84 0 0 0 2.73.67.09.09 0 0 0 .09-.1v-1.4c0-.03-.01-.06-.03-.08a.14.14 0 0 0-.08-.04l-1.4-.29a.1.1 0 0 0-.06 0 .16.16 0 0 0-.06.04l-1.2 1.2',
        },
      },
    ],
  },
  name: 'phone',
  theme: 'outlined',
};

export default PhoneOutlined;
