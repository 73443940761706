// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { EditorChoiceOutlined as EditorChoiceOutlinedSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const EditorChoiceOutlined = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={EditorChoiceOutlinedSvg} />
);

EditorChoiceOutlined.displayName = 'EditorChoiceOutlined';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(
  EditorChoiceOutlined,
);
