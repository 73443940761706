// This icon file is generated automatically.

import { IconDefinition } from '../types';

const UserPictureOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 12 12',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M1.33 10.03A6.72 6.72 0 0 1 6 8.13a6.5 6.5 0 0 1 4.67 1.9v-8.5a.2.2 0 0 0-.07-.13.2.2 0 0 0-.14-.07H1.54a.2.2 0 0 0-.14.07.2.2 0 0 0-.07.14v8.49M6 6.69c.6 0 1.11-.2 1.53-.63.43-.42.64-.93.64-1.53s-.21-1.12-.64-1.54A2.09 2.09 0 0 0 6 2.36c-.6 0-1.11.21-1.53.63-.43.42-.64.93-.64 1.54 0 .6.21 1.1.64 1.53.42.42.93.63 1.53.63Zm-4.46 4.98c-.34 0-.62-.12-.86-.35a1.16 1.16 0 0 1-.35-.86V1.54c0-.34.12-.62.35-.86.24-.23.52-.35.86-.35h8.92c.34 0 .62.12.86.35.23.24.35.52.35.86v8.92c0 .34-.12.62-.35.86-.24.23-.52.35-.86.35H1.54m.75-1h7.42v-.14A5.36 5.36 0 0 0 6 9.13a5.72 5.72 0 0 0-3.7 1.38v.16M6 5.69c-.32 0-.6-.11-.82-.34-.23-.23-.35-.5-.35-.82 0-.32.12-.6.35-.83.22-.23.5-.34.82-.34.32 0 .6.11.82.34.23.23.35.5.35.83 0 .32-.12.6-.35.82-.22.23-.5.34-.82.34',
        },
      },
    ],
  },
  name: 'user-picture',
  theme: 'outlined',
};

export default UserPictureOutlined;
