// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { InfoCircleOutlined as InfoCircleOutlinedSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const InfoCircleOutlined = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={InfoCircleOutlinedSvg} />
);

InfoCircleOutlined.displayName = 'InfoCircleOutlined';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(
  InfoCircleOutlined,
);
