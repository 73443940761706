// This icon file is generated automatically.

import { IconDefinition } from '../types';

const PaperCopyOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 10 14',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M5 10.04 7.44 7.6l-.7-.7L5.5 8.1V5.17h-1V8.1L3.27 6.9l-.7.7L5 10.04m-3.8 3.3c-.33 0-.62-.12-.85-.36a1.16 1.16 0 0 1-.35-.85V4.47L3.8.67h5c.33 0 .62.11.85.35.23.23.35.52.35.85v10.26c0 .33-.12.62-.35.85-.23.24-.52.35-.86.35H1.21Zm0-1h7.6a.2.2 0 0 0 .14-.07.2.2 0 0 0 .06-.14V1.87a.2.2 0 0 0-.06-.14.2.2 0 0 0-.15-.06H4.23L1 4.9v7.23c0 .05.02.1.06.14a.2.2 0 0 0 .15.06m0 0H1h8-7.8Z',
        },
      },
    ],
  },
  name: 'paper-copy',
  theme: 'outlined',
};

export default PaperCopyOutlined;
