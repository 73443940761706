// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { ExitDoorOutlined as ExitDoorOutlinedSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const ExitDoorOutlined = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={ExitDoorOutlinedSvg} />
);

ExitDoorOutlined.displayName = 'ExitDoorOutlined';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(
  ExitDoorOutlined,
);
