// This icon file is generated automatically.

import { IconDefinition } from '../types';

const CheckFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 16 16',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          'clip-rule': 'evenodd',
          d: 'M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m3.62-10.58v-.01l-.02-.02a.65.65 0 0 0-.96 0L7.02 9.28 5.38 7.5a.65.65 0 0 0-.96 0 .7.7 0 0 0 0 .93l1.94 2.09c.17.2.41.3.65.3.26 0 .49-.11.66-.3l3.9-4.2a.7.7 0 0 0 .05-.9Z',
          'fill-rule': 'evenodd',
        },
      },
    ],
  },
  name: 'check',
  theme: 'filled',
};

export default CheckFilled;
