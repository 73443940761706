// This icon file is generated automatically.

import { IconDefinition } from '../types';

const SheetOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 18 20',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M3 19.5c-.7 0-1.29-.24-1.77-.73A2.41 2.41 0 0 1 .5 17v-2.88h3V.5h14V17c0 .7-.24 1.29-.73 1.77-.48.49-1.07.73-1.77.73H3ZM15 18c.28 0 .52-.1.71-.29.2-.19.29-.43.29-.71V2H5v12.12h9V17c0 .28.1.52.29.71.19.2.43.29.71.29M6.2 6.7V5.2h8.6v1.5H6.2m0 2.88v-1.5h8.6v1.5H6.2ZM3 18h9.5v-2.38H2V17c0 .28.1.52.29.71.19.2.43.29.71.29m0 0H2h10.5H3Z',
        },
      },
    ],
  },
  name: 'sheet',
  theme: 'outlined',
};

export default SheetOutlined;
