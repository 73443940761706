// This icon file is generated automatically.

import { IconDefinition } from '../types';

const CloseOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 21.05 21.03',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'm12.58 10.51 8.05-8.05a1.44 1.44 0 0 0 .12-1.9l-.12-.14a1.44 1.44 0 0 0-1.9-.12l-8.2 8.19-8.1-8.1L2.3.28A1.46 1.46 0 0 0 .4.44L.29.59a1.45 1.45 0 0 0 .13 1.88l8.06 8.05L.4 18.6l-.11.14a1.44 1.44 0 0 0 2.17 1.87l8.07-8.08 8.07 8.08a1.44 1.44 0 0 0 2.03 0 1.46 1.46 0 0 0 .12-1.9l-1.8-1.81Z',
        },
      },
    ],
  },
  name: 'close',
  theme: 'outlined',
};

export default CloseOutlined;
