// This icon file is generated automatically.

import { IconDefinition } from '../types';

const FireOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 10 13',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M1 7.33a3.8 3.8 0 0 0 1.8 3.28 1.15 1.15 0 0 1-.13-.58c0-.3.06-.6.17-.87.12-.27.3-.52.51-.74L5 6.8l1.65 1.62a2.2 2.2 0 0 1 .65 1.9c-.02.1-.06.2-.1.29A3.83 3.83 0 0 0 9 7.33a4.06 4.06 0 0 0-1.2-2.9A2.67 2.67 0 0 1 3.68 2.5c-.43.36-.81.74-1.15 1.13-.33.4-.61.8-.84 1.2-.23.42-.4.83-.52 1.26A4.7 4.7 0 0 0 1 7.33m4 .87-.95.93a1.32 1.32 0 0 0-.38.9c0 .36.13.66.39.92s.57.38.94.38.68-.12.94-.38c.26-.26.4-.56.4-.92 0-.17-.04-.34-.1-.49a1.35 1.35 0 0 0-.29-.4L5 8.2M4.67.6v1.6a1.64 1.64 0 0 0 2.78 1.21l.29-.3A5.03 5.03 0 0 1 10 7.34c0 1.4-.48 2.58-1.45 3.55A4.83 4.83 0 0 1 5 12.33c-1.4 0-2.58-.48-3.55-1.45A4.83 4.83 0 0 1 0 7.33c0-1.28.42-2.52 1.26-3.71.84-1.2 1.97-2.2 3.4-3.02Z',
        },
      },
    ],
  },
  name: 'fire',
  theme: 'outlined',
};

export default FireOutlined;
