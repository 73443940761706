// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { Arrow45DegOutlined as Arrow45DegOutlinedSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const Arrow45DegOutlined = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={Arrow45DegOutlinedSvg} />
);

Arrow45DegOutlined.displayName = 'Arrow45DegOutlined';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(
  Arrow45DegOutlined,
);
