// This icon file is generated automatically.

import { IconDefinition } from '../types';

const AlarmOnOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 16 16',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'm7.3 10.8 3.54-3.53-.72-.72L7.3 9.38 5.88 7.95l-.72.72L7.3 10.8m.7 3.53a5.5 5.5 0 0 1-4-1.66 5.74 5.74 0 0 1-1.67-4A5.5 5.5 0 0 1 4 4.66 5.74 5.74 0 0 1 8 3a5.5 5.5 0 0 1 4 1.66 5.74 5.74 0 0 1 1.67 4A5.5 5.5 0 0 1 12 12.68 5.74 5.74 0 0 1 8 14.33M3.84 1.93l.7.7L1.96 5.2l-.7-.7 2.58-2.58Zm8.32 0 2.58 2.57-.7.7-2.58-2.57.7-.7M8 13.33c1.3 0 2.4-.45 3.3-1.36.91-.9 1.37-2 1.37-3.3 0-1.3-.46-2.4-1.36-3.3C10.4 4.44 9.3 4 8 4c-1.3 0-2.4.45-3.3 1.36-.91.9-1.37 2.01-1.37 3.3 0 1.3.46 2.4 1.37 3.31.9.9 2 1.36 3.3 1.36',
        },
      },
    ],
  },
  name: 'alarm-on',
  theme: 'outlined',
};

export default AlarmOnOutlined;
