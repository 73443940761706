// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { CallCenterOutlined as CallCenterOutlinedSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const CallCenterOutlined = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={CallCenterOutlinedSvg} />
);

CallCenterOutlined.displayName = 'CallCenterOutlined';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(
  CallCenterOutlined,
);
