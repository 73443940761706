// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { CaretLeftOutlined as CaretLeftOutlinedSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const CaretLeftOutlined = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={CaretLeftOutlinedSvg} />
);

CaretLeftOutlined.displayName = 'CaretLeftOutlined';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(
  CaretLeftOutlined,
);
