// This icon file is generated automatically.

import { IconDefinition } from '../types';

const JoyStickOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 22 16',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M3.73 15.75c-.95 0-1.75-.33-2.4-1a3.43 3.43 0 0 1-.94-3.27l2.1-8.4A3.7 3.7 0 0 1 6.13.25h9.75a3.7 3.7 0 0 1 3.63 2.83l2.1 8.4a5.52 5.52 0 0 1 .12.87c0 .95-.34 1.76-1.02 2.41-.67.66-1.48.99-2.43.99a3.38 3.38 0 0 1-3.08-1.92l-.71-1.45c-.1-.21-.26-.37-.46-.47-.21-.1-.43-.16-.65-.16H8.63c-.23 0-.45.05-.65.15a1 1 0 0 0-.47.47l-.7 1.46a3.43 3.43 0 0 1-3.08 1.92m.07-1.5a1.83 1.83 0 0 0 1.67-1.06l.7-1.43c.23-.48.57-.84 1.01-1.11.45-.27.93-.4 1.45-.4h4.74c.52 0 1 .14 1.45.42.44.27.79.64 1.03 1.1l.7 1.42a1.83 1.83 0 0 0 1.68 1.06 1.95 1.95 0 0 0 1.97-1.87l-.06-.54-2.1-8.37c-.13-.5-.4-.92-.8-1.24-.39-.32-.85-.48-1.36-.48H6.13c-.53 0-1 .16-1.4.48-.4.32-.66.74-.77 1.24l-2.1 8.37c-.03.09-.05.26-.06.51 0 .54.2.99.59 1.35a2 2 0 0 0 1.41.55m8.7-7.37c.24 0 .45-.08.63-.25a.85.85 0 0 0 .25-.63.85.85 0 0 0-.25-.63.85.85 0 0 0-.63-.25.85.85 0 0 0-.63.25.85.85 0 0 0-.25.63c0 .24.08.45.25.63.18.17.39.25.63.25m2-2c.24 0 .45-.08.63-.25a.85.85 0 0 0 .25-.63.85.85 0 0 0-.25-.63.85.85 0 0 0-.63-.25.85.85 0 0 0-.63.25.85.85 0 0 0-.25.63c0 .24.08.45.25.63.18.17.39.25.63.25m0 4c.24 0 .45-.08.63-.25a.85.85 0 0 0 .25-.63.85.85 0 0 0-.25-.63.85.85 0 0 0-.63-.25.85.85 0 0 0-.63.25.85.85 0 0 0-.25.63c0 .24.08.45.25.63.18.17.39.25.63.25m2-2c.24 0 .45-.08.63-.25a.85.85 0 0 0 .25-.63.85.85 0 0 0-.25-.63.85.85 0 0 0-.63-.25.85.85 0 0 0-.63.25.85.85 0 0 0-.25.63c0 .24.08.45.25.63.18.17.39.25.63.25m-9 1.47a.58.58 0 0 0 .6-.6V6.6h1.15a.58.58 0 0 0 .6-.6.58.58 0 0 0-.6-.6H8.1V4.25a.58.58 0 0 0-.6-.6.58.58 0 0 0-.6.6V5.4H5.75a.58.58 0 0 0-.6.6.58.58 0 0 0 .6.6H6.9v1.15a.58.58 0 0 0 .6.6Z',
        },
      },
    ],
  },
  name: 'joy-stick',
  theme: 'outlined',
};

export default JoyStickOutlined;
