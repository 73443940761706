// This icon file is generated automatically.

import { IconDefinition } from '../types';

const HamburgerRightOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 29 19',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'm20.72 1.69 7.61 7.58-7.61 7.56-1.76-1.76 5.86-5.8-5.86-5.83z',
        },
      },
      {
        tag: 'path',
        attrs: {
          d: 'M15.48 0H0v2.5h15.48zM15.48 16H0v2.5h15.48zM20.35 8.08H0v2.5h20.35v-2.5',
        },
      },
    ],
  },
  name: 'hamburger-right',
  theme: 'outlined',
};

export default HamburgerRightOutlined;
