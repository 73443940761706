// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { HamburgerLeftOutlined as HamburgerLeftOutlinedSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const HamburgerLeftOutlined = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={HamburgerLeftOutlinedSvg} />
);

HamburgerLeftOutlined.displayName = 'HamburgerLeftOutlined';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(
  HamburgerLeftOutlined,
);
