// This icon file is generated automatically.

import { IconDefinition } from '../types';

const CaretDownOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 24 24',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M12 15.05 6.35 9.4 7.4 8.35l4.6 4.6 4.6-4.6 1.05 1.05L12 15.05',
        },
      },
    ],
  },
  name: 'caret-down',
  theme: 'outlined',
};

export default CaretDownOutlined;
