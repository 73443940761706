// This icon file is generated automatically.

import { IconDefinition } from '../types';

const InfoCircleOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 14 14',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M6.5 10.17h1V6.33h-1v3.84M7 5.19a.52.52 0 0 0 .54-.54.52.52 0 0 0-.16-.38.52.52 0 0 0-.38-.15.52.52 0 0 0-.38.15c-.1.1-.16.23-.16.38 0 .16.05.28.16.39.1.1.23.15.38.15m0 8.14a6.17 6.17 0 0 1-4.48-1.85 6.4 6.4 0 0 1-1.35-6.95 6.4 6.4 0 0 1 3.36-3.36C5.3.83 6.13.67 7 .67a6.17 6.17 0 0 1 4.48 1.85 6.4 6.4 0 0 1 1.35 6.95 6.4 6.4 0 0 1-3.36 3.36c-.77.34-1.6.5-2.47.5m0-1c1.49 0 2.75-.51 3.78-1.55A5.15 5.15 0 0 0 12.33 7c0-1.49-.51-2.75-1.55-3.78A5.15 5.15 0 0 0 7 1.67c-1.49 0-2.75.51-3.78 1.55A5.15 5.15 0 0 0 1.67 7c0 1.49.51 2.75 1.55 3.78A5.15 5.15 0 0 0 7 12.33',
        },
      },
    ],
  },
  name: 'info-circle',
  theme: 'outlined',
};

export default InfoCircleOutlined;
