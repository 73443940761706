'use client';

import * as React from 'react';
import classNames from 'classnames';
import type { IconDefinition } from 'icons-svg/templates/types';
import { normalizeTwoToneColors } from '../utils';
import Context from './Context';
import type { IconBaseProps } from './Icon';
import IconBase from './IconBase';
import type { TwoToneColor } from './twoTonePrimaryColor';
import { getTwoToneColor, setTwoToneColor } from './twoTonePrimaryColor';

export interface IconReactProps extends IconBaseProps {
  twoToneColor?: TwoToneColor;
}

export interface IconComponentProps extends IconReactProps {
  icon: IconDefinition;
}

// Initial setting
setTwoToneColor('#1890ff');

// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/34757#issuecomment-488848720
export interface IconBaseComponent<Props>
  extends React.ForwardRefExoticComponent<
    Props & React.RefAttributes<HTMLSpanElement>
  > {
  getTwoToneColor: typeof getTwoToneColor;
  setTwoToneColor: typeof setTwoToneColor;
}

const IconReact = React.forwardRef<HTMLSpanElement, IconComponentProps>(
  (props, ref) => {
    const {
      // affect outter <i>...</i>
      className,

      // affect inner <svg>...</svg>
      icon,
      spin,
      rotate,

      tabIndex,
      onClick,

      // other
      twoToneColor,

      ...restProps
    } = props;

    const { prefixCls = '', rootClassName } = React.useContext(Context);

    const classString = classNames(
      rootClassName,
      prefixCls,
      {
        [`${prefixCls}-${icon.name}`]: Boolean(icon.name),
        [`${prefixCls}-spin`]: Boolean(spin) || icon.name === 'loading',
      },
      className,
    );

    let iconTabIndex = tabIndex;
    if (iconTabIndex === undefined && onClick) {
      iconTabIndex = -1;
    }

    const svgStyle = rotate
      ? {
          msTransform: `rotate(${rotate}deg)`,
          transform: `rotate(${rotate}deg)`,
        }
      : undefined;

    const [primaryColor, secondaryColor] = normalizeTwoToneColors(twoToneColor);

    return (
      <span
        role="img"
        aria-label={icon.name}
        {...restProps}
        ref={ref}
        tabIndex={iconTabIndex}
        onClick={onClick}
        className={classString}
      >
        <IconBase
          icon={icon}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          style={svgStyle}
        />
      </span>
    );
  },
) as IconBaseComponent<IconComponentProps>;

IconReact.displayName = 'IconReact';
IconReact.getTwoToneColor = getTwoToneColor;
IconReact.setTwoToneColor = setTwoToneColor;

export default IconReact;
