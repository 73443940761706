import type { DOMAttributes } from 'react';
import * as React from 'react';
import type { AbstractNode, IconDefinition } from 'icons-svg/templates/types';

export function warning(valid: boolean, message: string): void {
  // eslint-disable-next-line no-console -- need console warning to see in browser
  console.warn(valid, `[icons] ${message}`);
}

export function isIconDefinition(
  target: IconDefinition,
): target is IconDefinition {
  return (
    typeof target === 'object' &&
    typeof target.name === 'string' &&
    typeof target.theme === 'string' &&
    (typeof target.icon === 'object' || typeof target.icon === 'function')
  );
}

export function normalizeAttrs(attrs: Attrs = {}): Attrs {
  return Object.keys(attrs).reduce((acc: Attrs, key) => {
    const val = attrs[key];
    switch (key) {
      case 'class':
        acc.className = val;
        delete acc.class;
        break;
      case 'fill-rule':
        acc.fillRule = val;
        delete acc['fill-rule'];
        break;
      case 'clip-rule':
        acc.clipRule = val;
        delete acc['clip-rule'];
        break;
      default:
        acc[key] = val;
    }
    return acc;
  }, {});
}

export type Attrs = Record<string, string>;

export function generate(
  node: AbstractNode,
  key: string,
  rootProps?: Record<string, unknown> | false,
): React.ReactElement<DOMAttributes<SVGElement>> {
  if (!rootProps) {
    return React.createElement(
      node.tag,
      { key, ...normalizeAttrs(node.attrs) },
      (node.children || []).map((child, index) =>
        generate(child, `${key}-${node.tag}-${index}`),
      ),
    );
  }
  return React.createElement(
    node.tag,
    {
      key,
      ...normalizeAttrs(node.attrs),
      ...rootProps,
    },
    (node.children || []).map((child, index) =>
      generate(child, `${key}-${node.tag}-${index}`),
    ),
  );
}

export function getSecondaryColor(primaryColor: string): string {
  // choose the second color
  return primaryColor;
}

export function normalizeTwoToneColors(
  twoToneColor: string | [string, string] | undefined,
): string[] {
  if (!twoToneColor) {
    return [];
  }

  return Array.isArray(twoToneColor) ? twoToneColor : [twoToneColor];
}

// These props make sure that the SVG behaviours like general text.
// Reference: https://blog.prototypr.io/align-svg-icons-to-text-and-say-goodbye-to-font-icons-d44b3d7b26b4
export const svgBaseProps = {
  width: '1em',
  height: '1em',
  fill: 'currentColor',
  'aria-hidden': 'true',
  focusable: 'false',
};
