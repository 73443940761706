// This icon file is generated automatically.

import { IconDefinition } from '../types';

const UserOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 16 16',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M8 7.8c-.64 0-1.2-.23-1.65-.7-.45-.45-.68-1-.68-1.64 0-.64.23-1.19.68-1.65.46-.45 1-.68 1.65-.68.64 0 1.2.23 1.65.68.45.46.68 1.01.68 1.65 0 .64-.23 1.2-.68 1.65-.46.46-1 .68-1.65.68m-5 5.07V11.4a1.78 1.78 0 0 1 .98-1.55 8.63 8.63 0 0 1 8.05 0A1.78 1.78 0 0 1 13 11.4v1.48H3m1-1h8v-.48a.65.65 0 0 0-.12-.38.89.89 0 0 0-.32-.28 7.6 7.6 0 0 0-7.13 0 .89.89 0 0 0-.31.28.65.65 0 0 0-.12.38v.48M8 6.8c.37 0 .68-.13.94-.39.26-.26.4-.57.4-.94s-.14-.68-.4-.94c-.26-.26-.57-.4-.94-.4s-.68.14-.94.4c-.26.26-.4.57-.4.94s.14.68.4.94c.26.26.57.4.94.4',
        },
      },
    ],
  },
  name: 'user',
  theme: 'outlined',
};

export default UserOutlined;
