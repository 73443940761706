// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { CheckFilled as CheckFilledSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const CheckFilled = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={CheckFilledSvg} />
);

CheckFilled.displayName = 'CheckFilled';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(CheckFilled);
