// This icon file is generated automatically.

import { IconDefinition } from '../types';

const LineOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 12 12',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M1.21 11.33A1.2 1.2 0 0 1 0 10.12V7.75h1v2.37c0 .05.02.1.06.14a.2.2 0 0 0 .14.06h8.92a.2.2 0 0 0 .14-.06.2.2 0 0 0 .06-.14V1.21a.2.2 0 0 0-.06-.14.2.2 0 0 0-.14-.06H1.21a.2.2 0 0 0-.14.06.2.2 0 0 0-.06.14v6.68h-1V1.21A1.2 1.2 0 0 1 1.22 0h8.92a1.2 1.2 0 0 1 1.21 1.21v8.92a1.2 1.2 0 0 1-1.21 1.21H1.21v-.01Z',
        },
      },
      {
        tag: 'path',
        attrs: {
          d: 'M9.06 5.19c0 .61-.23 1.15-.72 1.69a10.7 10.7 0 0 1-2.67 1.97c-.36.15-.31-.1-.3-.18l.05-.29a.81.81 0 0 0-.01-.31c-.04-.09-.19-.14-.3-.17-1.63-.22-2.84-1.36-2.84-2.71 0-1.52 1.52-2.75 3.39-2.75s3.39 1.24 3.39 2.75h.01M7.84 6.42c.38-.41.54-.8.54-1.23 0-1.11-1.19-2.07-2.71-2.07s-2.71.97-2.71 2.07c0 .98.92 1.87 2.25 2.04h.05c.37.09.64.22.79.59v.03c.62-.4 1.43-1.01 1.8-1.43h-.01m-.09-1.36c.1 0 .17.09.17.19 0 .09-.08.16-.17.17h-.5v.32h.5c.1 0 .18.07.19.17.01.1-.07.18-.17.19H7.08a.18.18 0 0 1-.18-.18V4.57c0-.1.08-.18.18-.18h.67c.1 0 .18.08.18.18 0 .1-.08.18-.18.18h-.5v.32h.5v-.01m-1.09.85c0 .1-.08.18-.18.18a.17.17 0 0 1-.14-.07l-.69-.94v.83c0 .1-.09.17-.19.16a.16.16 0 0 1-.16-.16V4.56c0-.1.08-.18.18-.18a.2.2 0 0 1 .14.07l.7.94v-.83c0-.1.08-.18.18-.18.1 0 .18.08.18.18v1.35h-.02m-1.62 0c0 .1-.08.18-.18.18a.18.18 0 0 1-.18-.18V4.56c0-.1.08-.18.18-.18.1 0 .18.08.18.18v1.35m-.7.18h-.67a.18.18 0 0 1-.18-.18V4.56c0-.1.1-.17.19-.16.09 0 .15.08.16.16v1.17h.5c.1 0 .18.08.18.18 0 .1-.08.18-.18.18',
        },
      },
    ],
  },
  name: 'line',
  theme: 'outlined',
};

export default LineOutlined;
