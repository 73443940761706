// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { FunnelOutlined as FunnelOutlinedSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const FunnelOutlined = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={FunnelOutlinedSvg} />
);

FunnelOutlined.displayName = 'FunnelOutlined';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(
  FunnelOutlined,
);
