// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { GiftBoxOutlined as GiftBoxOutlinedSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const GiftBoxOutlined = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={GiftBoxOutlinedSvg} />
);

GiftBoxOutlined.displayName = 'GiftBoxOutlined';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(
  GiftBoxOutlined,
);
