// This icon file is generated automatically.

import { IconDefinition } from '../types';

const PlusFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 16 16',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          'clip-rule': 'evenodd',
          d: 'M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 3.71a.86.86 0 0 1 .86.86v2.57h2.57a.86.86 0 0 1 0 1.72H8.86v2.57a.86.86 0 0 1-1.72 0V8.86H4.57a.86.86 0 1 1 0-1.72h2.57V4.57A.86.86 0 0 1 8 3.71',
          'fill-rule': 'evenodd',
        },
      },
    ],
  },
  name: 'plus',
  theme: 'filled',
};

export default PlusFilled;
