// This icon file is generated automatically.

import { IconDefinition } from '../types';

const Arrow45DegOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 20 20',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'm5.2 14.4 9.6-9.6m0 0H7.6m7.2 0V12',
          'stroke-linecap': 'round',
          'stroke-linejoin': 'round',
          'stroke-width': '1.2',
          stroke: 'currentColor',
        },
      },
    ],
  },
  name: 'arrow-45-deg',
  theme: 'outlined',
};

export default Arrow45DegOutlined;
