// This icon file is generated automatically.

import { IconDefinition } from '../types';

const HamburgerLeftOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 29 19',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M0 0v2.5h20.36V0zm0 7.91v2.5h15.48v-2.5zm28.34-4.55L26.58 1.6l-7.61 7.56 7.61 7.58 1.76-1.75-5.86-5.83 5.86-5.8M0 15.83v2.5h20.36v-2.5z',
        },
      },
      {
        tag: 'path',
        attrs: {
          d: 'M15.48 7.91H0v2.5h15.48v-2.5M20.36 0H0v2.5h20.36zM20.36 15.83H0v2.5h20.36z',
        },
      },
      {
        tag: 'path',
        attrs: {
          d: 'm22.48 9.16 5.86 5.83-1.76 1.75-7.61-7.58 7.61-7.56 1.76 1.76-5.86 5.8',
        },
      },
    ],
  },
  name: 'hamburger-left',
  theme: 'outlined',
};

export default HamburgerLeftOutlined;
