// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { CheckOutlined as CheckOutlinedSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const CheckOutlined = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={CheckOutlinedSvg} />
);

CheckOutlined.displayName = 'CheckOutlined';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(CheckOutlined);
