// This icon file is generated automatically.

import { IconDefinition } from '../types';

const WalletOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 13 12',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M1.54 11.67c-.33 0-.62-.12-.85-.36a1.16 1.16 0 0 1-.36-.85V1.54C.33 1.2.45.92.7.69c.23-.24.52-.36.85-.36h8.92c.33 0 .62.12.85.36.24.23.36.52.36.85v1.81h-1V1.54a.2.2 0 0 0-.06-.15.2.2 0 0 0-.15-.06H1.54a.2.2 0 0 0-.15.06.2.2 0 0 0-.06.15v8.92a.2.2 0 0 0 .2.2h8.93a.2.2 0 0 0 .15-.05.2.2 0 0 0 .06-.15V8.65h1v1.81c0 .33-.12.62-.36.85-.23.24-.52.36-.85.36H1.54ZM6.87 9c-.33 0-.61-.12-.85-.35a1.16 1.16 0 0 1-.35-.86V4.21c0-.34.11-.62.35-.86.24-.23.52-.35.85-.35h4.26c.33 0 .61.12.85.35.24.24.35.52.35.86v3.58c0 .34-.11.62-.35.86-.24.23-.52.35-.85.35H6.87m4.26-1a.2.2 0 0 0 .15-.06.2.2 0 0 0 .05-.15V4.21a.2.2 0 0 0-.05-.15.2.2 0 0 0-.15-.06H6.87a.2.2 0 0 0-.15.06.2.2 0 0 0-.05.15v3.58c0 .06.02.11.05.15a.2.2 0 0 0 .15.06h4.26M8.67 7c.27 0 .51-.1.7-.3.2-.19.3-.42.3-.7 0-.28-.1-.51-.3-.7a.96.96 0 0 0-.7-.3c-.28 0-.52.1-.71.3-.2.19-.3.42-.3.7 0 .28.1.51.3.7.2.2.43.3.7.3',
        },
      },
    ],
  },
  name: 'wallet',
  theme: 'outlined',
};

export default WalletOutlined;
