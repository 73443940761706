// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { CheckCircleOutlined as CheckCircleOutlinedSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const CheckCircleOutlined = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={CheckCircleOutlinedSvg} />
);

CheckCircleOutlined.displayName = 'CheckCircleOutlined';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(
  CheckCircleOutlined,
);
