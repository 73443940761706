// This icon file is generated automatically.

import { IconDefinition } from '../types';

const ChainOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 19 22',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M14.5 14a3.74 3.74 0 0 0-2.68 1.13l-4.33-2.77c.34-.88.34-1.84 0-2.72l4.33-2.77A3.75 3.75 0 1 0 11 5.6L6.68 8.38a3.75 3.75 0 1 0 0 5.24l4.33 2.77A3.75 3.75 0 1 0 14.5 14m0-12a2.25 2.25 0 1 1 0 4.5 2.25 2.25 0 0 1 0-4.5ZM4 13.25a2.25 2.25 0 1 1 0-4.5 2.25 2.25 0 0 1 0 4.5ZM14.5 20a2.25 2.25 0 1 1 0-4.5 2.25 2.25 0 0 1 0 4.5',
          fill: 'currentColor',
        },
      },
    ],
  },
  name: 'chain',
  theme: 'outlined',
};

export default ChainOutlined;
