// This icon file is generated automatically.

import { IconDefinition } from '../types';

const BentoMenuFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 24 24',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'rect',
        attrs: { height: '10', rx: '2', width: '10', x: '1', y: '1' },
      },
      {
        tag: 'rect',
        attrs: { height: '10', rx: '2', width: '10', x: '1', y: '13' },
      },
      {
        tag: 'rect',
        attrs: { height: '2.5', rx: '1.25', width: '10', x: '13', y: '1' },
      },
      {
        tag: 'rect',
        attrs: { height: '2.5', rx: '1.25', width: '10', x: '13', y: '4.7' },
      },
      {
        tag: 'rect',
        attrs: { height: '2.5', rx: '1.25', width: '10', x: '13', y: '8.4' },
      },
      {
        tag: 'rect',
        attrs: { height: '10', rx: '2', width: '10', x: '13', y: '13' },
      },
    ],
  },
  name: 'bento-menu',
  theme: 'filled',
};

export default BentoMenuFilled;
