// This icon file is generated automatically.

import { IconDefinition } from '../types';

const LockPasswordOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 32 40',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M16 6a3 3 0 0 0-3 3h2a1 1 0 1 1 1 1h-1v3h2v-1.17A3 3 0 0 0 16 6M15 14h2v2h-2z',
        },
      },
      {
        tag: 'path',
        attrs: {
          d: 'M27.43 18.9v-7.18C27.43 5.26 22.3 0 16 0S4.57 5.26 4.57 11.72v7.19H0V40h32V18.9h-4.57M6.86 11.73c0-5.17 4.1-9.38 9.14-9.38 5.04 0 9.14 4.2 9.14 9.38v7.19H6.86v-7.2M29.7 37.66H2.3V21.25H29.7v16.4',
        },
      },
      {
        tag: 'path',
        attrs: {
          d: 'M7.01 36v-1.43H25V36H7Zm.78-5.73-.95-.57.83-1.52H6v-1.14h1.67l-.83-1.47.95-.57.83 1.47.84-1.47.95.57-.84 1.47h1.68v1.14H9.57l.84 1.52-.95.57-.84-1.52-.83 1.52m7.37 0-.95-.57.84-1.52h-1.67v-1.14h1.67l-.84-1.47.95-.57.84 1.47.84-1.47.95.57-.84 1.47h1.67v1.14h-1.67l.84 1.52-.95.57-.84-1.52-.84 1.52m7.38 0-.95-.57.84-1.52h-1.68v-1.14h1.68l-.84-1.47.95-.57.84 1.47.83-1.47.95.57-.83 1.47H26v1.14h-1.67l.83 1.52-.95.57-.83-1.52-.84 1.52',
        },
      },
    ],
  },
  name: 'lock-password',
  theme: 'outlined',
};

export default LockPasswordOutlined;
