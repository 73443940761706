// This icon file is generated automatically.

import { IconDefinition } from '../types';

const BentoFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 24 24',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M11.18 5.45v3.28a2.45 2.45 0 0 1-2.45 2.45H5.45A2.45 2.45 0 0 1 3 8.73V5.45A2.45 2.45 0 0 1 5.45 3h3.28a2.45 2.45 0 0 1 2.45 2.45M18.55 3h-3.28a2.45 2.45 0 0 0-2.45 2.45v3.28a2.45 2.45 0 0 0 2.45 2.45h3.28A2.45 2.45 0 0 0 21 8.73V5.45A2.45 2.45 0 0 0 18.55 3m-9.82 9.82H5.45A2.45 2.45 0 0 0 3 15.27v3.28A2.45 2.45 0 0 0 5.45 21h3.28a2.45 2.45 0 0 0 2.45-2.45v-3.28a2.45 2.45 0 0 0-2.45-2.45m9.82 0h-3.28a2.45 2.45 0 0 0-2.45 2.45v3.28A2.45 2.45 0 0 0 15.27 21h3.28A2.45 2.45 0 0 0 21 18.55v-3.28a2.45 2.45 0 0 0-2.45-2.45',
        },
      },
    ],
  },
  name: 'bento',
  theme: 'filled',
};

export default BentoFilled;
