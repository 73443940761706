// This icon file is generated automatically.

import { IconDefinition } from '../types';

const GiftBoxOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 14 14',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M1.74 13.33V6.08H.67V2.6H4.1a.88.88 0 0 1-.19-.36 1.77 1.77 0 0 1-.05-.43c0-.5.18-.92.53-1.27A1.73 1.73 0 0 1 6.38.17c.21.11.41.25.58.43.17-.18.37-.33.59-.43a1.73 1.73 0 0 1 1.98.37A1.73 1.73 0 0 1 10 2.23c-.04.13-.1.26-.2.37h3.53v3.48h-1.07v7.25H1.74ZM8.26 1.01a.77.77 0 0 0-.57.23.77.77 0 0 0-.23.57c0 .22.08.41.23.56.15.16.34.23.57.23.22 0 .41-.07.56-.23a.77.77 0 0 0 .23-.56.77.77 0 0 0-.23-.57.77.77 0 0 0-.56-.23m-3.39.8c0 .22.08.41.23.56.15.16.34.23.57.23.22 0 .41-.07.56-.23a.77.77 0 0 0 .23-.56.77.77 0 0 0-.23-.57.77.77 0 0 0-.56-.23.77.77 0 0 0-.57.23.77.77 0 0 0-.23.57m-3.2 1.8v1.47H6.5V3.6H1.67m4.83 8.72V6.08H2.74v6.25H6.5m1 0h3.76V6.08H7.5v6.25m4.83-7.25V3.6H7.5v1.48h4.83',
        },
      },
    ],
  },
  name: 'gift-box',
  theme: 'outlined',
};

export default GiftBoxOutlined;
