// This icon file is generated automatically.

import { IconDefinition } from '../types';

const OpenEyeFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 35 24',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'm34 11.01-.48-.74C31.43 7.02 27.08.26 17.6.26S3.77 7.01 1.69 10.27l-.49.75a1.68 1.68 0 0 0 0 1.85l.48.74c2.09 3.24 6.44 10 15.92 10s13.83-6.75 15.91-10l.49-.75c.37-.56.37-1.29 0-1.85m-16.4 7.6a6.67 6.67 0 1 1 .01-13.35 6.67 6.67 0 0 1-.01 13.35',
        },
      },
      {
        tag: 'path',
        attrs: {
          d: 'M17.6 15.27a3.34 3.34 0 1 0 0-6.68 3.34 3.34 0 0 0 0 6.68',
        },
      },
    ],
  },
  name: 'open-eye',
  theme: 'filled',
};

export default OpenEyeFilled;
