// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { ChainOutlined as ChainOutlinedSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const ChainOutlined = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={ChainOutlinedSvg} />
);

ChainOutlined.displayName = 'ChainOutlined';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(ChainOutlined);
