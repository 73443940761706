// This icon file is generated automatically.

import { IconDefinition } from '../types';

const BackOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 18 16',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          'clip-rule': 'evenodd',
          d: 'M.9 8.4A.57.57 0 0 1 .74 8a.55.55 0 0 1 .18-.4l4.2-4c.08-.08.19-.14.3-.16a.63.63 0 0 1 .35.03c.11.04.2.12.27.21.07.1.1.2.1.32v2.86h10.2c.32 0 .63.12.85.33.23.22.36.5.36.81 0 .3-.13.6-.36.8-.22.22-.53.34-.84.34H6.14V12c0 .11-.04.22-.1.32a.6.6 0 0 1-.28.2.63.63 0 0 1-.65-.12l-4.2-4Z',
          'fill-rule': 'evenodd',
        },
      },
    ],
  },
  name: 'back',
  theme: 'outlined',
};

export default BackOutlined;
