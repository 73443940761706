// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { BackOutlined as BackOutlinedSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const BackOutlined = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={BackOutlinedSvg} />
);

BackOutlined.displayName = 'BackOutlined';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(BackOutlined);
