// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { CaretRightOutlined as CaretRightOutlinedSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const CaretRightOutlined = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={CaretRightOutlinedSvg} />
);

CaretRightOutlined.displayName = 'CaretRightOutlined';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(
  CaretRightOutlined,
);
