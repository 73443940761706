// This icon file is generated automatically.

import { IconDefinition } from '../types';

const AlarmOffOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 16 16',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'm12.97 11.22-.74-.75a5.32 5.32 0 0 0 .35-1.76c0-1.3-.45-2.4-1.36-3.33A4.47 4.47 0 0 0 7.92 4a3.96 3.96 0 0 0-1.76.34l-.76-.76A5.9 5.9 0 0 1 7.92 3a5.53 5.53 0 0 1 4 1.66 5.8 5.8 0 0 1 1.66 4.05 5.43 5.43 0 0 1-.6 2.5m.98-6.01-2.57-2.58.7-.7 2.58 2.57-.7.7m-.33 9.94-2.11-2.11a5.76 5.76 0 0 1-3.6 1.3 5.53 5.53 0 0 1-4-1.65A5.65 5.65 0 0 1 2.6 6.75a5.4 5.4 0 0 1 .98-1.66l-.78-.77-.91.9-.7-.7.9-.9L.91 2.43l.7-.7 12.71 12.7-.7.71m-5.7-1.8a4.43 4.43 0 0 0 2.87-1.03l-6.52-6.5a4.63 4.63 0 0 0 3.65 7.52',
        },
      },
    ],
  },
  name: 'alarm-off',
  theme: 'outlined',
};

export default AlarmOffOutlined;
