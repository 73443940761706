// This icon file is generated automatically.

import { IconDefinition } from '../types';

const BinFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 18 16',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          'clip-rule': 'evenodd',
          d: 'M7.33 2.3c.26-.25.6-.42.96-.52a2.37 2.37 0 0 1 2.08.49c.28.24.48.54.58.87H6.78c.1-.32.29-.6.55-.84m-2.46.84c.15-.87.63-1.67 1.37-2.25C6.97.32 7.9 0 8.87 0c.96 0 1.9.32 2.63.9a3.65 3.65 0 0 1 1.36 2.24h3.47c.25 0 .49.1.66.25.18.16.28.38.28.6 0 .24-.1.45-.28.61a.98.98 0 0 1-.66.26H15.1v9.43c0 .22-.05.44-.14.65-.1.21-.23.4-.4.56a2.01 2.01 0 0 1-1.32.5H4.5a2.01 2.01 0 0 1-1.32-.5 1.71 1.71 0 0 1-.4-.56 1.6 1.6 0 0 1-.15-.65V4.86H1.4a.98.98 0 0 1-.66-.26.82.82 0 0 1-.28-.6c0-.23.1-.45.28-.6a.98.98 0 0 1 .66-.26h3.47m1.5 3.57c.44 0 .78.32.78.72V12c0 .2-.08.37-.22.5a.81.81 0 0 1-.55.22c-.21 0-.4-.08-.55-.21a.69.69 0 0 1-.23-.5V7.42c0-.4.35-.72.78-.72m5.76.72a.69.69 0 0 0-.22-.5.81.81 0 0 0-.55-.22c-.21 0-.4.08-.55.21a.69.69 0 0 0-.23.5V12c0 .2.08.37.23.5a.81.81 0 0 0 1.1 0 .69.69 0 0 0 .22-.5V7.43',
          'fill-rule': 'evenodd',
        },
      },
    ],
  },
  name: 'bin',
  theme: 'filled',
};

export default BinFilled;
