// This icon file is generated automatically.

import { IconDefinition } from '../types';

const WhatAppOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 12 12',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M10.22 1.74A5.98 5.98 0 0 0 0 5.94c0 .97.25 1.93.73 2.82L.02 12l3.28-.75a5.98 5.98 0 0 0 8.7-5.3 5.9 5.9 0 0 0-1.78-4.2m-4.23 9.22c-.84 0-1.67-.21-2.4-.61l-.16-.09-2.18.5.47-2.15-.09-.16a5 5 0 0 1-.7-2.5A5.04 5.04 0 0 1 9.57 2.4a4.98 4.98 0 0 1-3.57 8.55',
          fill: 'currentColor',
        },
      },
      {
        tag: 'path',
        attrs: {
          d: 'M4.36 3.32H4.1a.5.5 0 0 0-.37.18c-.12.13-.48.46-.48 1.14 0 .67.5 1.32.56 1.4.07.1.95 1.52 2.35 2.07 1.15.46 1.4.37 1.64.34.25-.02.81-.33.93-.65.11-.32.11-.59.08-.65-.04-.05-.13-.09-.27-.16-.13-.06-.8-.4-.93-.45-.13-.04-.22-.06-.3.07-.1.14-.37.46-.45.55-.08.09-.16.1-.3.03-.13-.07-.57-.21-1.1-.68a4.2 4.2 0 0 1-.77-.96c-.08-.14 0-.21.07-.28l.2-.22c.08-.08.1-.14.14-.23.05-.1.03-.17 0-.24-.04-.07-.3-.75-.43-1.02-.1-.22-.2-.23-.3-.24',
          fill: 'currentColor',
        },
      },
    ],
  },
  name: 'what-app',
  theme: 'outlined',
};

export default WhatAppOutlined;
