// This icon file is generated automatically.

import { IconDefinition } from '../types';

const DollarPaperOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 16 20',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M7.42 16.63h1.5v-1h1.25c.22 0 .4-.08.54-.22a.73.73 0 0 0 .21-.54v-3a.73.73 0 0 0-.75-.75H6.92v-1.5h4v-1.5h-2v-1h-1.5v1H6.17c-.21 0-.39.08-.53.22a.73.73 0 0 0-.22.53v3c0 .22.07.4.22.54.14.14.32.21.53.21h3.25v1.5h-4v1.5h2v1M2.31 19.5c-.5 0-.93-.18-1.28-.52a1.74 1.74 0 0 1-.53-1.29V2.31c0-.5.18-.93.52-1.28C1.38.68 1.8.5 2.32.5h7.94l5.25 5.25v11.94c0 .5-.18.93-.53 1.29-.35.34-.77.52-1.28.52H2.31ZM9.5 5.88V2H2.3a.3.3 0 0 0-.2.1.3.3 0 0 0-.1.2v15.4c0 .07.03.14.1.2.06.07.13.1.2.1h11.4a.3.3 0 0 0 .2-.1.3.3 0 0 0 .1-.2V5.87H9.5',
        },
      },
    ],
  },
  name: 'dollar-paper',
  theme: 'outlined',
};

export default DollarPaperOutlined;
