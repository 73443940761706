// This icon file is generated automatically.

import { IconDefinition } from '../types';

const CardOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 20 19',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'm13.07 12.42 1-3.65-3.12-2.15-1 3.64 3.12 2.16M2.3 17.06l-.63-.3c-.52-.22-.87-.6-1.05-1.13a2.02 2.02 0 0 1 .07-1.55l1.6-3.47v6.45Zm4.19 1.82c-.55 0-1.02-.2-1.41-.6-.4-.39-.59-.86-.59-1.41v-5.35l2.44 6.71.14.35c.04.1.1.2.18.3H6.5m4.76-.54a1.8 1.8 0 0 1-1.4-.06c-.46-.2-.77-.55-.94-1.02L4.67 5.57c-.17-.47-.15-.93.06-1.38.21-.46.56-.77 1.03-.94L12.73.7c.47-.17.93-.15 1.38.06.46.21.77.55.94 1.03l4.26 11.68c.17.47.15.94-.06 1.4-.2.45-.55.76-1.02.93l-6.97 2.54m-.52-1.43 6.97-2.53a.31.31 0 0 0 .18-.16.29.29 0 0 0 0-.24L13.64 2.31a.31.31 0 0 0-.16-.18.29.29 0 0 0-.23 0L6.29 4.65a.31.31 0 0 0-.18.16.29.29 0 0 0 0 .24l4.23 11.67c.04.08.09.14.16.18.08.03.16.04.24 0',
        },
      },
    ],
  },
  name: 'card',
  theme: 'outlined',
};

export default CardOutlined;
