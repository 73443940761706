// This icon file is generated automatically.

import { IconDefinition } from '../types';

const MagnifyingGlassOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 12 12',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M11.03 11.72 6.84 7.53a3.81 3.81 0 0 1-2.5.88c-1.13 0-2.1-.4-2.89-1.18a3.93 3.93 0 0 1-1.18-2.9c0-1.13.4-2.1 1.18-2.89A3.93 3.93 0 0 1 4.35.26c1.13 0 2.1.4 2.89 1.18a3.93 3.93 0 0 1 1.18 2.9 3.91 3.91 0 0 1-.88 2.49l4.2 4.19-.71.7M4.35 7.4c.86 0 1.58-.3 2.18-.9.6-.59.9-1.32.9-2.18 0-.86-.3-1.58-.9-2.18-.6-.6-1.32-.9-2.18-.9-.86 0-1.59.3-2.19.9-.6.6-.9 1.32-.9 2.18 0 .86.3 1.59.9 2.19.6.6 1.33.89 2.19.89',
        },
      },
    ],
  },
  name: 'magnifying-glass',
  theme: 'outlined',
};

export default MagnifyingGlassOutlined;
