// This icon file is generated automatically.

import { IconDefinition } from '../types';

const MobilePhoneOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 14 14',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M.94 8.17V3.83h1v4.34h-1m11.12 2V5.83h1v4.34h-1m-7.59 3.16c-.33 0-.62-.11-.85-.35a1.16 1.16 0 0 1-.35-.85V1.87c0-.34.12-.62.35-.85.23-.24.52-.35.85-.35h5.06c.33 0 .62.11.85.35.23.23.35.51.35.85v10.26c0 .33-.12.62-.35.85-.23.24-.52.35-.85.35H4.47m5.26-1.83H4.27v.63c0 .05.02.1.06.14a.2.2 0 0 0 .14.06h5.06a.2.2 0 0 0 .14-.06.2.2 0 0 0 .06-.14v-.63m-5.46-9h5.46v-.63a.2.2 0 0 0-.06-.14.2.2 0 0 0-.14-.06H4.47a.2.2 0 0 0-.14.06.2.2 0 0 0-.06.14v.63Zm0 8h5.46v-7H4.27v7',
        },
      },
    ],
  },
  name: 'mobile-phone',
  theme: 'outlined',
};

export default MobilePhoneOutlined;
