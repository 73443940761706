// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { PersonalCardOutlined as PersonalCardOutlinedSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const PersonalCardOutlined = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={PersonalCardOutlinedSvg} />
);

PersonalCardOutlined.displayName = 'PersonalCardOutlined';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(
  PersonalCardOutlined,
);
