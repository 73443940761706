// This icon file is generated automatically.

import { IconDefinition } from '../types';

const InfoFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 27.98 28',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M12.68.04A14.15 14.15 0 0 0 2.31 6.25a15.08 15.08 0 0 0-1.98 4.6A11.76 11.76 0 0 0 0 14a11.81 11.81 0 0 0 .33 3.15 14.47 14.47 0 0 0 2.48 5.3 14.12 14.12 0 0 0 9.26 5.45 18.3 18.3 0 0 0 3.84 0 14.27 14.27 0 0 0 6.55-2.73 19.58 19.58 0 0 0 2.7-2.7 14.27 14.27 0 0 0 2.72-6.55 18.3 18.3 0 0 0 0-3.83 14.2 14.2 0 0 0-3.53-7.52A13.99 13.99 0 0 0 16.09.14a18.67 18.67 0 0 0-3.41-.1m3.51 5.57a1.94 1.94 0 0 1 1 2.58 2.35 2.35 0 0 1-.99.98 2.28 2.28 0 0 1-2.91-.94 2.14 2.14 0 0 1-.06-1.54 2.3 2.3 0 0 1 1.05-1.1 2.33 2.33 0 0 1 1.91.02Zm-2.12 5.68a2.21 2.21 0 0 1 1.86 2.52 12.74 12.74 0 0 1-.63 2.83 12.08 12.08 0 0 0-.73 3.28c-.01.79.16 1 .89 1.1a3.2 3.2 0 0 0 1.36-.17l.32-.1a3.39 3.39 0 0 1-.31.98 22.52 22.52 0 0 1-2.16.77 6.43 6.43 0 0 1-1.86 0 2.38 2.38 0 0 1-1.27-.69 1.83 1.83 0 0 1-.59-1.61 10.38 10.38 0 0 1 .66-3.2 12.02 12.02 0 0 0 .7-3.13c0-.57-.09-.8-.4-.99-.17-.09-.29-.1-.77-.1a2.3 2.3 0 0 0-.98.15l-.4.14.03-.12.13-.5.1-.37.3-.12a16.6 16.6 0 0 1 2-.66 5.43 5.43 0 0 1 1.75-.01Z',
        },
      },
    ],
  },
  name: 'info',
  theme: 'filled',
};

export default InfoFilled;
