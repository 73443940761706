// This icon file is generated automatically.

import { IconDefinition } from '../types';

const PapersOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 11 13',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M4.04 10.67c-.34 0-.62-.12-.86-.35a1.16 1.16 0 0 1-.35-.86V1.87c0-.33.12-.62.35-.85.24-.24.52-.35.86-.35h5.59c.33 0 .62.11.85.35.24.23.35.52.35.85v7.6c0 .33-.11.61-.35.85-.23.23-.52.35-.85.35h-5.6m0-1h5.59a.2.2 0 0 0 .14-.07.2.2 0 0 0 .06-.14V1.87a.2.2 0 0 0-.06-.14.2.2 0 0 0-.14-.06h-5.6a.2.2 0 0 0-.13.06.2.2 0 0 0-.07.14v7.6c0 .04.02.09.07.13a.2.2 0 0 0 .14.07M1.7 13c-.34 0-.63-.12-.86-.35a1.16 1.16 0 0 1-.35-.85V3.2h1v8.6c0 .05.02.1.06.14a.2.2 0 0 0 .15.06h6.58v1H1.71',
        },
      },
    ],
  },
  name: 'papers',
  theme: 'outlined',
};

export default PapersOutlined;
