// This icon file is generated automatically.

import { IconDefinition } from '../types';

const GiftOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 20 18',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M2 13.79v1.9c0 .08.03.15.1.21.06.07.13.1.2.1h15.4a.3.3 0 0 0 .2-.1.3.3 0 0 0 .1-.2v-1.91H2Zm.3-10.27h2.78a1.92 1.92 0 0 1-.25-1c0-.7.24-1.3.72-1.77A2.41 2.41 0 0 1 8.5.35c.36.21.67.48.93.8l.56.75.56-.74c.25-.34.56-.61.93-.82a2.41 2.41 0 0 1 2.94.4 2.41 2.41 0 0 1 .48 2.78h2.8c.5 0 .93.17 1.29.52.34.35.52.78.52 1.29v10.36c0 .5-.18.94-.52 1.29-.36.34-.78.52-1.29.52H2.31c-.5 0-.93-.18-1.28-.52a1.74 1.74 0 0 1-.53-1.29V5.33c0-.5.18-.94.53-1.29.35-.35.77-.52 1.28-.52m-.3 7.7h16v-5.9a.3.3 0 0 0-.1-.2.3.3 0 0 0-.2-.1h-5.47l2.04 2.8-1.2.85-3.09-4.2-3.1 4.2-1.19-.86 2.01-2.8H2.3a.3.3 0 0 0-.2.1.3.3 0 0 0-.1.22v5.88m5.33-7.7c.28 0 .52-.1.7-.29.2-.19.3-.43.3-.71 0-.28-.1-.52-.3-.71a.97.97 0 0 0-.7-.3c-.29 0-.52.1-.72.3-.19.19-.28.43-.28.7a.97.97 0 0 0 1 1m5.3 0c.29 0 .53-.1.72-.29.19-.19.28-.43.28-.71a.97.97 0 0 0-1-1c-.28 0-.52.1-.7.29-.2.19-.3.43-.3.7 0 .3.1.53.3.72.18.2.42.29.7.29',
        },
      },
    ],
  },
  name: 'gift',
  theme: 'outlined',
};

export default GiftOutlined;
