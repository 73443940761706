// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { UserPictureOutlined as UserPictureOutlinedSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const UserPictureOutlined = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={UserPictureOutlinedSvg} />
);

UserPictureOutlined.displayName = 'UserPictureOutlined';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(
  UserPictureOutlined,
);
