// This icon file is generated automatically.

import { IconDefinition } from '../types';

const FunnelOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 10 10',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M4.59 10a.57.57 0 0 1-.42-.17.57.57 0 0 1-.17-.42V5.55L.27.81A.48.48 0 0 1 .2.28C.31.09.46 0 .67 0h8.66c.21 0 .37.1.46.28.09.18.07.36-.06.53L6 5.55v3.86c0 .17-.06.3-.17.42a.57.57 0 0 1-.42.17h-.82M5 5.2 8.3 1H1.7L5 5.2',
        },
      },
    ],
  },
  name: 'funnel',
  theme: 'outlined',
};

export default FunnelOutlined;
