// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { CreditCardOutlined as CreditCardOutlinedSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const CreditCardOutlined = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={CreditCardOutlinedSvg} />
);

CreditCardOutlined.displayName = 'CreditCardOutlined';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(
  CreditCardOutlined,
);
