// This icon file is generated automatically.

import { IconDefinition } from '../types';

const FacebookFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 11 22',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          'clip-rule': 'evenodd',
          d: 'M3.35 22h3.98v-9.83h2.95l.58-3.97H7.33v-3c0-1.12 1-1.75 1.95-1.75H11V.15L7.92.02C4.98-.19 3.35 2.3 3.35 5.24V8.2H0v3.97h3.35V22',
          fill: 'currentColor',
        },
      },
    ],
  },
  name: 'facebook',
  theme: 'filled',
};

export default FacebookFilled;
