// This icon file is generated automatically.

import { IconDefinition } from '../types';

const CylindOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 12 12',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M6 11.67c-1.62 0-2.97-.23-4.05-.69C.87 10.52.33 9.95.33 9.27v-6.6c0-.65.56-1.2 1.66-1.65A10.5 10.5 0 0 1 6 .33c1.57 0 2.9.23 4 .69 1.11.45 1.67 1 1.67 1.65v6.6c0 .68-.54 1.25-1.62 1.71-1.08.46-2.43.69-4.05.69m0-7.7c.97 0 1.95-.14 2.94-.41.98-.28 1.55-.57 1.7-.89-.14-.33-.7-.63-1.69-.91a10.59 10.59 0 0 0-5.9-.01c-.97.27-1.54.57-1.71.9.17.33.74.63 1.7.9.97.28 1.96.42 2.96.42Zm0 3.34a13.36 13.36 0 0 0 2.6-.26 8.47 8.47 0 0 0 2.07-.75V3.93a8.47 8.47 0 0 1-2.06.75A11.55 11.55 0 0 1 6 4.94a13.26 13.26 0 0 1-2.64-.27c-.4-.08-.77-.19-1.1-.31a5.42 5.42 0 0 1-.93-.43V6.3a8.47 8.47 0 0 0 2.03.74A11.83 11.83 0 0 0 6 7.31m0 3.36a11.73 11.73 0 0 0 3.02-.4c.42-.13.78-.27 1.08-.43.3-.17.48-.34.57-.52V7.3a8.47 8.47 0 0 1-2.06.75A11.55 11.55 0 0 1 6 8.3a13.26 13.26 0 0 1-2.64-.27c-.4-.09-.77-.2-1.1-.32a5.42 5.42 0 0 1-.93-.42v2.03c.09.19.28.36.57.52.29.15.65.3 1.07.42a11.85 11.85 0 0 0 3.03.4',
        },
      },
    ],
  },
  name: 'cylind',
  theme: 'outlined',
};

export default CylindOutlined;
