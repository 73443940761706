// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { LockOutlined as LockOutlinedSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const LockOutlined = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={LockOutlinedSvg} />
);

LockOutlined.displayName = 'LockOutlined';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(LockOutlined);
