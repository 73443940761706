// This icon file is generated automatically.

import { IconDefinition } from '../types';

const ErrorFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 30 30',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M15 0A15 15 0 1 1 0 15 15 15 0 0 1 15 0m0 19.4a1.3 1.3 0 0 0-1.3 1.3 1.31 1.31 0 1 0 1.3-1.3M15 8a1.33 1.33 0 0 0-1.32 1.32v6.63a1.31 1.31 0 0 0 2.63 0V9.32A1.32 1.32 0 0 0 15 7.99Z',
        },
      },
    ],
  },
  name: 'error',
  theme: 'filled',
};

export default ErrorFilled;
