// This icon file is generated automatically.

import { IconDefinition } from '../types';

const RerollLockOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 15 12',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M8.67 11.67a5.5 5.5 0 0 1-2.14-.42 5.87 5.87 0 0 1-1.8-1.17l.73-.7c.43.4.91.71 1.45.94.54.23 1.13.35 1.76.35 1.29 0 2.4-.46 3.3-1.37A4.5 4.5 0 0 0 13.33 6a4.5 4.5 0 0 0-1.36-3.3 4.5 4.5 0 0 0-3.3-1.37 4.5 4.5 0 0 0-3.3 1.37A4.5 4.5 0 0 0 4 6v.52L5.33 5.2l.7.7L3.5 8.43.96 5.89l.71-.7L3 6.54V6a5.5 5.5 0 0 1 1.66-4 5.74 5.74 0 0 1 4-1.67A5.5 5.5 0 0 1 12.68 2a5.74 5.74 0 0 1 1.66 4c0 1.58-.55 2.92-1.65 4.02a5.46 5.46 0 0 1-4.01 1.66M7.33 8.59a.6.6 0 0 1-.44-.18.6.6 0 0 1-.18-.45v-2c0-.18.06-.33.19-.45a.67.67 0 0 1 .47-.18v-.7c0-.36.13-.66.38-.92.26-.25.56-.38.92-.38.35 0 .66.13.91.38.25.26.38.56.38.92v.7c.19 0 .35.06.48.18.12.12.19.27.19.45v2a.6.6 0 0 1-.18.45.6.6 0 0 1-.45.18H7.33m.63-3.26h1.41v-.7c0-.2-.07-.37-.2-.5a.68.68 0 0 0-.5-.2c-.2 0-.37.06-.5.2-.14.13-.2.3-.2.5v.7',
        },
      },
    ],
  },
  name: 'reroll-lock',
  theme: 'outlined',
};

export default RerollLockOutlined;
