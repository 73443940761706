// This icon file is generated automatically.

import { IconDefinition } from '../types';

const TelegramFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 48 48',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'm18.83 30.36-.79 11.17c1.14 0 1.63-.49 2.22-1.07l5.32-5.1 11.04 8.09c2.02 1.13 3.45.53 4-1.86l7.24-33.95c.64-3-1.08-4.16-3.05-3.43L2.23 20.51c-2.9 1.13-2.86 2.75-.5 3.49l10.89 3.38 25.29-15.82c1.19-.79 2.27-.35 1.38.44L18.83 30.36Z',
          fill: 'currentColor',
        },
      },
    ],
  },
  name: 'telegram',
  theme: 'filled',
};

export default TelegramFilled;
