// This icon file is generated automatically.

import { IconDefinition } from '../types';

const ClockOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 16 16',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'm10.32 11.02.7-.7L8.5 7.8V4.67h-1V8.2l2.82 2.82M8 14.33a6.17 6.17 0 0 1-4.48-1.85 6.4 6.4 0 0 1-1.35-6.95 6.4 6.4 0 0 1 3.36-3.36c.77-.34 1.6-.5 2.47-.5a6.17 6.17 0 0 1 4.48 1.85 6.4 6.4 0 0 1 1.35 6.95 6.4 6.4 0 0 1-3.36 3.36c-.77.34-1.6.5-2.47.5m0-1c1.48 0 2.74-.52 3.77-1.55A5.14 5.14 0 0 0 13.33 8c0-1.48-.52-2.74-1.56-3.78A5.14 5.14 0 0 0 8 2.68c-1.48 0-2.74.52-3.78 1.55A5.14 5.14 0 0 0 2.67 8c0 1.48.52 2.74 1.55 3.78A5.14 5.14 0 0 0 8 13.33',
        },
      },
    ],
  },
  name: 'clock',
  theme: 'outlined',
};

export default ClockOutlined;
