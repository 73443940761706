// This icon file is generated automatically.

import { IconDefinition } from '../types';

const CreditCardOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 14 10',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M13.33 1.2v7.6c0 .33-.11.62-.35.85-.23.23-.52.35-.85.35H1.87c-.33 0-.62-.12-.85-.35a1.16 1.16 0 0 1-.35-.86V1.21c0-.34.11-.63.35-.86.23-.23.52-.35.85-.35h10.26c.33 0 .62.12.85.35.24.23.35.52.35.86M1.67 2.6h10.66V1.2a.2.2 0 0 0-.06-.14.2.2 0 0 0-.14-.06H1.87a.2.2 0 0 0-.14.06.2.2 0 0 0-.06.15v1.4Zm0 2.13V8.8c0 .06.02.1.06.15a.2.2 0 0 0 .14.06h10.26a.2.2 0 0 0 .14-.06.2.2 0 0 0 .06-.15V4.73H1.67Z',
        },
      },
    ],
  },
  name: 'credit-card',
  theme: 'outlined',
};

export default CreditCardOutlined;
