// This icon file is generated automatically.

import { IconDefinition } from '../types';

const CaretLeftOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 15.36 28.02',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M14.97 27.62a1.36 1.36 0 0 0 .13-1.77l-.13-.15L3.27 14l11.7-11.68A1.36 1.36 0 0 0 15.1.55L14.97.4A1.36 1.36 0 0 0 13.2.27l-.15.13L.4 13.05a1.36 1.36 0 0 0-.13 1.77l.13.15 12.65 12.65a1.36 1.36 0 0 0 1.92 0',
        },
      },
    ],
  },
  name: 'caret-left',
  theme: 'outlined',
};

export default CaretLeftOutlined;
