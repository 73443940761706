// This icon file is generated automatically.

import { IconDefinition } from '../types';

const UserFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 40 40',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M33.2 30.95a19.1 19.1 0 0 1-26.36.02l-.43-.4.03-1.14a3.65 3.65 0 0 1 .53-2.31c.69-1.14 2.02-2.05 4.48-3.05a47.6 47.6 0 0 0 3.25-1.46c.87-.41 1.07-.53 1.27-.77a1.99 1.99 0 0 0 .49-1.14c0-.12.03-.44.06-.7l.07-.47-.28-.33a7.76 7.76 0 0 1-1.79-3.2c-.1-.37-.1-.37-.37-.44a.9.9 0 0 1-.65-.42 18.47 18.47 0 0 1-.45-2.57.81.81 0 0 1 .44-.71l.21-.1-.04-.38a12.55 12.55 0 0 1-.04-1.33 5.1 5.1 0 0 1 .18-1.65 5.2 5.2 0 0 1 1.27-2.47 4.43 4.43 0 0 1 3.24-1.48c.34.02.44 0 .59-.1a3.38 3.38 0 0 1 1.1-.5 3.86 3.86 0 0 1 1.77.03 7.18 7.18 0 0 1 3.6 2.72 5.87 5.87 0 0 1 .95 4.06c-.03.37-.08.76-.1.87-.03.17-.01.2.23.3.52.23.58.45.4 1.56l-.18 1.14c-.1.67-.28.9-.81 1.04l-.27.06-.1.43a6.29 6.29 0 0 1-1.65 2.92l-.4.42.07.74a2.6 2.6 0 0 0 .47 1.61c.21.27.34.35 1.28.81a79.63 79.63 0 0 0 3.61 1.66c2.54 1.05 3.73 1.94 4.34 3.24a4.79 4.79 0 0 1 .39 2.23v.9Z',
        },
      },
    ],
  },
  name: 'user',
  theme: 'filled',
};

export default UserFilled;
