// This icon file is generated automatically.

import { IconDefinition } from '../types';

const RewardOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 18 21',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M3.7 20.5v-7.59L.35 7.5l4.31-7h8.66l4.31 7-3.33 5.41v7.59L9 18.7l-5.3 1.8m1.5-2.12L9 17.1l3.8 1.27V14.5H5.2v3.88M5.5 2l-3.4 5.5 3.4 5.5h6.98l3.4-5.5-3.4-5.5H5.51m2.44 9.22-3.2-3.17 1.08-1.07 2.12 2.13 4.23-4.25 1.06 1.04-5.29 5.32',
        },
      },
    ],
  },
  name: 'reward',
  theme: 'outlined',
};

export default RewardOutlined;
