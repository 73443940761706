// This icon file is generated automatically.

import { IconDefinition } from '../types';

const UserImageOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 18 20',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M9 15.63a8.27 8.27 0 0 0-5.64 2.15v.16l.07.05.09.01h10.94a.2.2 0 0 0 .08-.01.36.36 0 0 0 .08-.05v-.15A8.08 8.08 0 0 0 9 15.63m-7 1.35c.9-.88 1.95-1.57 3.14-2.08 1.19-.51 2.48-.77 3.86-.77a9.74 9.74 0 0 1 7 2.85V4.31a.3.3 0 0 0-.1-.21.3.3 0 0 0-.2-.1H2.3a.3.3 0 0 0-.2.1.3.3 0 0 0-.1.2v12.68Zm7-5.23c-.9 0-1.67-.32-2.3-.95a3.13 3.13 0 0 1-.95-2.3c0-.9.32-1.67.95-2.3.63-.63 1.4-.95 2.3-.95.9 0 1.67.32 2.3.95.63.63.95 1.4.95 2.3 0 .9-.32 1.67-.95 2.3-.63.63-1.4.95-2.3.95m0-1.5c.48 0 .9-.17 1.24-.51.34-.35.51-.76.51-1.24s-.17-.9-.51-1.24A1.69 1.69 0 0 0 9 6.75c-.48 0-.9.17-1.24.51-.34.35-.51.76-.51 1.24s.17.9.51 1.24c.35.34.76.51 1.24.51M2.3 19.5c-.5 0-.92-.18-1.27-.52a1.74 1.74 0 0 1-.53-1.29V4.31c0-.5.18-.93.52-1.28.36-.35.78-.53 1.29-.53h1.38V.38h1.54V2.5h7.58V.38h1.5V2.5h1.38c.5 0 .94.18 1.29.53.34.35.52.77.52 1.28v13.38c0 .5-.18.93-.52 1.29-.36.34-.78.52-1.29.52H2.31',
        },
      },
    ],
  },
  name: 'user-image',
  theme: 'outlined',
};

export default UserImageOutlined;
