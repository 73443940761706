// This icon file is generated automatically.

import { IconDefinition } from '../types';

const InstagramOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 22 22',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          'clip-rule': 'evenodd',
          d: 'M5.93 0h10.14A5.97 5.97 0 0 1 22 5.95v10.1A5.97 5.97 0 0 1 16.07 22H5.93A5.97 5.97 0 0 1 0 16.05V5.95A5.97 5.97 0 0 1 5.93 0m10.98 3.84c.68 0 1.26.58 1.26 1.27 0 .68-.58 1.26-1.26 1.26-.74 0-1.26-.58-1.26-1.26 0-.69.52-1.27 1.26-1.27M10.98 5h.05a6.06 6.06 0 0 1 5.99 6c0 3.31-2.73 6-6 6h-.04a6.01 6.01 0 0 1 0-12m0 2.05h.05a3.97 3.97 0 0 1 0 7.95h-.05a3.98 3.98 0 0 1 0-7.95m-5-5.16h10.04A4.1 4.1 0 0 1 20.12 6v10a4.1 4.1 0 0 1-4.1 4.1H5.99a4.1 4.1 0 0 1-4.1-4.1V6A4.1 4.1 0 0 1 6 1.9',
          'fill-rule': 'evenodd',
          fill: 'currentColor',
        },
      },
    ],
  },
  name: 'instagram',
  theme: 'outlined',
};

export default InstagramOutlined;
