// This icon file is generated automatically.

import { IconDefinition } from '../types';

const CalendarOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 12 14',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M3.33 8.3a.57.57 0 0 1-.41-.18.57.57 0 0 1-.18-.41c0-.17.06-.3.18-.42a.57.57 0 0 1 .41-.17c.17 0 .3.05.42.17.12.11.17.25.17.42 0 .16-.05.3-.17.41a.57.57 0 0 1-.42.17M6 8.3a.57.57 0 0 1-.42-.18.57.57 0 0 1-.17-.41c0-.17.06-.3.17-.42A.57.57 0 0 1 6 7.12c.16 0 .3.05.42.17.11.11.17.25.17.42 0 .16-.06.3-.17.41A.57.57 0 0 1 6 8.3m2.67 0a.57.57 0 0 1-.42-.18.57.57 0 0 1-.17-.41c0-.17.05-.3.17-.42a.57.57 0 0 1 .42-.17c.16 0 .3.05.41.17.12.11.18.25.18.42 0 .16-.06.3-.18.41a.57.57 0 0 1-.41.17m-7.13 5.03c-.34 0-.62-.11-.86-.35a1.16 1.16 0 0 1-.35-.85V3.2c0-.34.12-.63.35-.86.24-.23.52-.35.86-.35h.92V.59H3.5V2h5.05V.59h1V2h.92c.34 0 .62.12.86.35.23.23.35.52.35.86v8.92c0 .33-.12.62-.35.85-.24.24-.52.35-.86.35H1.54m0-1h8.92a.2.2 0 0 0 .14-.06.2.2 0 0 0 .07-.14V5.87H1.33v6.26c0 .05.02.1.07.14a.2.2 0 0 0 .14.06m-.2-7.46h9.33V3.21a.2.2 0 0 0-.07-.15.2.2 0 0 0-.14-.06H1.54a.2.2 0 0 0-.14.06.2.2 0 0 0-.07.15v1.66',
        },
      },
    ],
  },
  name: 'calendar',
  theme: 'outlined',
};

export default CalendarOutlined;
