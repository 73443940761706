// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { CloseOutlined as CloseOutlinedSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const CloseOutlined = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={CloseOutlinedSvg} />
);

CloseOutlined.displayName = 'CloseOutlined';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(CloseOutlined);
