// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { CaretDownOutlined as CaretDownOutlinedSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const CaretDownOutlined = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={CaretDownOutlinedSvg} />
);

CaretDownOutlined.displayName = 'CaretDownOutlined';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(
  CaretDownOutlined,
);
