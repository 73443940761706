// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { DollarPaperOutlined as DollarPaperOutlinedSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const DollarPaperOutlined = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={DollarPaperOutlinedSvg} />
);

DollarPaperOutlined.displayName = 'DollarPaperOutlined';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(
  DollarPaperOutlined,
);
