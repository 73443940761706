// This icon file is generated automatically.

import { IconDefinition } from '../types';

const CheckOutlined: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      viewBox: '0 0 21.05 14.96',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'm20.63 2.45-12.1 12.1a1.44 1.44 0 0 1-2.01 0L.38 8.42a1.42 1.42 0 0 1 0-1.93 1.43 1.43 0 0 1 2.03-.1l5.1 5.1L18.6.42a1.44 1.44 0 1 1 2.04 2.03',
        },
      },
    ],
  },
  name: 'check',
  theme: 'outlined',
};

export default CheckOutlined;
